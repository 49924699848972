//This page will show if you try to go page that not exist
import Card from "../components/card/Card"; 

export default function NotFound() {
    return (
        <Card>
            <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                <h1>Sivua ei löytynyt. <a href="/">Palaa kotisivuun tästä.</a></h1>
                <p>Sivua ei ole olemassa.</p>
                <p>
                    Mikäli pääsit tänne <a href="/">itse nettisivun</a> kautta, tarkoittaa se todennäköisesti sitä, että:</p>
                <ul>
                    <li>olet päätynyt linkkiin, joka on kirjoitettu väärin; tai</li>
                    <li>olet päätynyt linkkiin ominaisuuteen, jota ei olla lisätty vielä tai se ei toimi.</li>
                </ul>
                <p>
                    Missä tahansa tapauksessa, jos päädyt tänne sivun kautta niin ota <a href="/yhteystiedot">yhteyttä</a> kehitystiimiin!
                </p>
            </div>
        </Card>
    )
}
//the purpose of this page is to inform the user about the terms we use
import React from 'react'
import Card from '../components/card/Card'
import { useLocation } from 'react-router-dom';
import returnArrow from "../assets/returnArrow.png";

function InfoPage() {
  const location = useLocation();
  const state = location.state;
  return (
      <Card>
        <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={returnArrow} alt="takaisin" style={{ width: "40px", cursor: "pointer", marginRight: "1rem" }} onClick={() => window.history.back()}></img>
            <h2 style={{ margin: 0 }}>{state.title}</h2>
          </div>
          <p style={{ whiteSpace: "pre-line" }}>
            {state.text}
          </p>
        </div>
      </Card>
  )
}

export default InfoPage
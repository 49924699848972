//Idea of this page is to add new estates to our system
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Form from "../components/form/Form";
import { url } from "./HomePage";
import classes from "./AddEstate.module.css";

function AddEstate() {
  const [isLoading, setIsLoading] = useState(true);
  const [defaults, setDefaults] = useState("");
  const navigate = useNavigate();

  //checks your rights and pulls all data
  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 401) {
        console.log("token invalid");
        window.location.href = "/";
      }
      return setIsLoading(false);
    });
    fetch(url + "api/defaults", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Oletusarvojen lataaminen epäonnistui");
        }
        return response.json();
      })
      .then((data) => {
        setDefaults(data);
        /* console.log(data); */
      });
  }, []);

  function sanitizeNumberString(value) {
    return value.replace(/[.,](?![\d])/g, "");
  }

  //function for posting data to backend
  function addEstateHandler(estateData) {
    /* console.log(estateData); */
    var oldConstructed = sanitizeNumberString(estateData.constructed + "");
    var newConstructed = oldConstructed.split(",").join(".");
    var oldFloors = sanitizeNumberString(estateData.floors + "");
    var newFloors = oldFloors.split(",").join(".");
    var oldApartments = sanitizeNumberString(estateData.apartments + "");
    var newApartments = oldApartments.split(",").join(".");
    var oldResidendialArea = sanitizeNumberString(
      estateData.residentialArea + ""
    );
    var newResidentialArea = oldResidendialArea.split(",").join(".");
    var oldCommercialArea = sanitizeNumberString(
      estateData.commercialArea + ""
    );
    var newCommercialArea = oldCommercialArea.split(",").join(".");
    //
    var calculatedRoofData =
      Number(newResidentialArea) + Number(newCommercialArea);
    var calculatedYardData =
      Number(newResidentialArea) + Number(newCommercialArea);
    /* var calculatedYardCoverData = Number(newResidentialArea) + Number(newCommercialArea); */
    //
    var oldApartmentArea = sanitizeNumberString(estateData.apartmentArea + "");
    var newApartmentArea = oldApartmentArea
      .split(",")
      .join(".")
      .replace(" ", "");
    var oldSewer_area = sanitizeNumberString(estateData.sewer_area + "");
    var newSewer_area = oldSewer_area.split(",").join(".").replace(" ", "");
    var oldSewer_renovated = sanitizeNumberString(
      estateData.sewer_renovated + ""
    );
    var newSewer_renovated = oldSewer_renovated.split(",").join(".");
    var oldSewer_plannedRenovation = sanitizeNumberString(
      estateData.sewer_plannedRenovation + ""
    );
    var newSewer_plannedRenovation = oldSewer_plannedRenovation
      .split(",")
      .join(".");
    if (newSewer_plannedRenovation === "") {
      newSewer_plannedRenovation = 0;
    }
    var oldSewer_rPrice = sanitizeNumberString(estateData.sewer_rPrice + "");
    var newSewer_rPrice = oldSewer_rPrice.split(",").join(".");
    if (Number(newSewer_rPrice) === defaults[17]) {
      newSewer_rPrice = 0;
    }
    var newSewer_rRPrice = 0;
    var oldPlumbing_area = sanitizeNumberString(estateData.plumbing_area + "");
    var newPlumbing_area = oldPlumbing_area
      .split(",")
      .join(".")
      .replace(" ", "");
    var oldPlumbing_renovated = sanitizeNumberString(
      estateData.plumbing_renovated + ""
    );
    var newPlumbing_renovated = oldPlumbing_renovated.split(",").join(".");
    var oldPlumbing_plannedRenovation = sanitizeNumberString(
      estateData.plumbing_plannedRenovation + ""
    );
    var newPlumbing_plannedRenovation = oldPlumbing_plannedRenovation
      .split(",")
      .join(".");
    if (newPlumbing_plannedRenovation === "") {
      newPlumbing_plannedRenovation = 0;
    }
    var oldPlumbing_rPrice = sanitizeNumberString(
      estateData.plumbing_rPrice + ""
    );
    var newPlumbing_rPrice = oldPlumbing_rPrice.split(",").join(".");
    if (Number(newPlumbing_rPrice) === defaults[15]) {
      newPlumbing_rPrice = 0;
    }
    var newPlumbing_rRPrice = 0;
    var oldBathrooms = sanitizeNumberString(estateData.bathrooms + "");
    var newBathrooms = oldBathrooms.split(",").join(".").replace(" ", "");
    var oldBathroom_renovated = sanitizeNumberString(
      estateData.bathroom_renovated + ""
    );
    var newBathroom_renovated = oldBathroom_renovated.split(",").join(".");
    var oldBathroom_plannedRenovation = sanitizeNumberString(
      estateData.bathroom_plannedRenovation + ""
    );
    var newBathroom_plannedRenovation = oldBathroom_plannedRenovation
      .split(",")
      .join(".");
    if (newBathroom_plannedRenovation === "") {
      newBathroom_plannedRenovation = 0;
    }
    var oldBathroom_price = sanitizeNumberString(
      estateData.bathroom_price + ""
    );
    var newBathroom_price = oldBathroom_price.split(",").join(".");
    if (Number(newBathroom_price) === defaults[10]) {
      newBathroom_price = 0;
    }
    var newBathroom_rRPrice = 0;
    var oldHeating_area = sanitizeNumberString(estateData.heating_area + "");
    var newHeating_area = oldHeating_area.split(",").join(".").replace(" ", "");
    var oldHeating_renovated = sanitizeNumberString(
      estateData.heating_renovated + ""
    );
    var newHeating_renovated = oldHeating_renovated.split(",").join(".");
    var oldHeating_plannedRenovation = sanitizeNumberString(
      estateData.heating_plannedRenovation + ""
    );
    var newHeating_plannedRenovation = oldHeating_plannedRenovation
      .split(",")
      .join(".");
    if (newHeating_plannedRenovation === "") {
      newHeating_plannedRenovation = 0;
    }
    var oldHeating_rPrice = sanitizeNumberString(
      estateData.heating_rPrice + ""
    );
    var newHeating_rPrice = oldHeating_rPrice.split(",").join(".");
    if (Number(newHeating_rPrice) === defaults[14]) {
      newHeating_rPrice = 0;
    }
    var newHeating_rRPrice = 0;
    var oldElec_area = sanitizeNumberString(estateData.elec_area + "");
    var newElec_area = oldElec_area.split(",").join(".").replace(" ", "");
    var oldElec_renovated = sanitizeNumberString(
      estateData.elec_renovated + ""
    );
    var newElec_renovated = oldElec_renovated.split(",").join(".");
    var oldElec_plannedRenovation = sanitizeNumberString(
      estateData.elec_plannedRenovation + ""
    );
    var newElec_plannedRenovation = oldElec_plannedRenovation
      .split(",")
      .join(".");
    if (newElec_plannedRenovation === "") {
      newElec_plannedRenovation = 0;
    }
    var oldElec_rPrice = sanitizeNumberString(estateData.elec_rPrice + "");
    var newElec_rPrice = oldElec_rPrice.split(",").join(".");
    if (Number(newElec_rPrice) === defaults[11]) {
      newElec_rPrice = 0;
    }
    var newElec_rRPrice = 0;
    var oldVent_area = sanitizeNumberString(estateData.vent_area + "");
    var newVent_area = oldVent_area.split(",").join(".").replace(" ", "");
    var oldVent_renovated = sanitizeNumberString(
      estateData.vent_renovated + ""
    );
    var newVent_renovated = oldVent_renovated.split(",").join(".");
    var oldVent_plannedRenovation = sanitizeNumberString(
      estateData.vent_plannedRenovation + ""
    );
    var newVent_plannedRenovation = oldVent_plannedRenovation
      .split(",")
      .join(".");
    if (newVent_plannedRenovation === "") {
      newVent_plannedRenovation = 0;
    }
    var oldVent_rPrice = sanitizeNumberString(estateData.vent_rPrice + "");
    var newVent_rPrice = oldVent_rPrice.split(",").join(".");
    if (Number(newVent_rPrice) === defaults[18]) {
      newVent_rPrice = 0;
    }
    var newVent_rRPrice = 0;
    var oldFront_area = sanitizeNumberString(estateData.front_area + "");
    var newFront_area = oldFront_area.split(",").join(".").replace(" ", "");
    var oldFront_renovated = sanitizeNumberString(
      estateData.front_renovated + ""
    );
    var newFront_renovated = oldFront_renovated.split(",").join(".");
    var oldFront_plannedRenovation = sanitizeNumberString(
      estateData.front_plannedRenovation + ""
    );
    var newFront_plannedRenovation = oldFront_plannedRenovation
      .split(",")
      .join(".");
    if (newFront_plannedRenovation === "") {
      newFront_plannedRenovation = 0;
    }
    var oldFront_rPrice = sanitizeNumberString(estateData.front_rPrice + "");
    var newFront_rPrice = oldFront_rPrice.split(",").join(".");
    if (Number(newFront_rPrice) === defaults[13]) {
      newFront_rPrice = 0;
    }
    var newFront_rRPrice = 0;

    var oldWindows_and_balconies_area = sanitizeNumberString(estateData.windows_and_balconies_area + "");
    var newWindows_and_balconies_area = oldWindows_and_balconies_area.split(",").join(".").replace(" ", "");
    var oldWindows_and_balconies_renovated = sanitizeNumberString(
      estateData.windows_and_balconies_renovated + ""
    );
    var newWindows_and_balconies_renovated = oldWindows_and_balconies_renovated.split(",").join(".");
    var oldWindows_and_balconies_plannedRenovation = sanitizeNumberString(
      estateData.windows_and_balconies_plannedRenovation + ""
    );
    var newWindows_and_balconies_plannedRenovation = oldWindows_and_balconies_plannedRenovation
      .split(",")
      .join(".");
    if (newWindows_and_balconies_plannedRenovation === "") {
      newWindows_and_balconies_plannedRenovation = 0;
    }
    var oldWindows_and_balconies_rPrice = sanitizeNumberString(estateData.windows_and_balconies_rPrice + "");
    var newWindows_and_balconies_rPrice = oldWindows_and_balconies_rPrice.split(",").join(".");
    if (Number(newWindows_and_balconies_rPrice) === defaults[26]) {
      newWindows_and_balconies_rPrice = 0;
    }
    var newWindows_and_balconies_rRPrice = 0;
    /* var oldYard_area = sanitizeNumberString(estateData.yard_area + "")
    var newYard_area = oldYard_area.split(",").join(".") */
    var oldYard_renovated = sanitizeNumberString(
      estateData.yard_renovated + ""
    );
    var newYard_renovated = oldYard_renovated.split(",").join(".");
    var oldYard_plannedRenovation = sanitizeNumberString(
      estateData.yard_plannedRenovation + ""
    );
    var newYard_plannedRenovation = oldYard_plannedRenovation
      .split(",")
      .join(".");
    if (newYard_plannedRenovation === "") {
      newYard_plannedRenovation = 0;
    }
    var oldYard_price = sanitizeNumberString(estateData.yard_price + "");
    var newYard_price = oldYard_price.split(",").join(".");
    if (Number(newYard_price) === defaults[19]) {
      newYard_price = 0;
    }
    var newYard_rRPrice = 0;
    var oldYard_cover_area = sanitizeNumberString(
      estateData.yard_cover_area + ""
    );
    var newYard_cover_area = oldYard_cover_area.split(",").join(".");
    var oldYard_cover_renovated = sanitizeNumberString(
      estateData.yard_cover_renovated + ""
    );
    var newYard_cover_renovated = oldYard_cover_renovated.split(",").join(".");
    var oldYard_cover_plannedRenovation = sanitizeNumberString(
      estateData.yard_cover_plannedRenovation + ""
    );
    var newYard_cover_plannedRenovation = oldYard_cover_plannedRenovation
      .split(",")
      .join(".");
    if (newYard_cover_plannedRenovation === "") {
      newYard_cover_plannedRenovation = 0;
    }
    var oldYard_cover_price = sanitizeNumberString(
      estateData.yard_cover_price + ""
    );
    var newYard_cover_price = oldYard_cover_price.split(",").join(".");
    if (Number(newYard_cover_price) === defaults[19]) {
      newYard_cover_price = 0;
    }
    var newYard_cover_rRPrice = 0;
    /* var oldRoof_area = sanitizeNumberString(estateData.roof_area + "")
    var newRoof_area = oldRoof_area.split(",").join(".") */
    var oldRoof_renovated = sanitizeNumberString(
      estateData.roof_renovated + ""
    );
    var newRoof_renovated = oldRoof_renovated.split(",").join(".");
    var oldRoof_plannedRenovation = sanitizeNumberString(
      estateData.roof_plannedRenovation + ""
    );
    var newRoof_plannedRenovation = oldRoof_plannedRenovation
      .split(",")
      .join(".");
    if (newRoof_plannedRenovation === "") {
      newRoof_plannedRenovation = 0;
    }
    var oldRoof_price = sanitizeNumberString(estateData.roof_price + "");
    var newRoof_price = oldRoof_price.split(",").join(".");
    if (
      Number(newRoof_price) ===
      Math.round(
        ((Math.round(estateData.residentialArea) +
          Math.round(estateData.commercialArea)) /
          Math.round(estateData.floors)) *
          1.2
      ) *
        defaults[16]
    ) {
      newRoof_price = 0;
    }
    var newRoof_rRPrice = 0;
    var oldElevators = sanitizeNumberString(estateData.elevators + "");
    var newElevators = oldElevators.split(",").join(".");
    var oldElevators_renovated = sanitizeNumberString(
      estateData.elevators_renovated + ""
    );
    var newElevators_renovated = oldElevators_renovated.split(",").join(".");
    var oldElevators_plannedRenovation = sanitizeNumberString(
      estateData.elevators_plannedRenovation + ""
    );
    var newElevators_plannedRenovation = oldElevators_plannedRenovation
      .split(",")
      .join(".");
    if (newElevators_plannedRenovation === "") {
      newElevators_plannedRenovation = 0;
    }
    var oldElevators_price = sanitizeNumberString(
      estateData.elevators_price + ""
    );
    var newElevators_price = oldElevators_price.split(",").join(".");
    if (Number(newElevators_price) === defaults[12]) {
      newElevators_price = 0;
    }
    var newElevators_rRPrice = 0;

    //fomats data
    var body = `{
         "name":"${estateData.name}",
         "address":"${estateData.address}",
         "postNumber":"${estateData.postNumber}",
         "city":"${estateData.city}",
         "moreInfo":"${estateData.moreInfo}",
         "propertyType":${estateData.propertyType},
         "constructed":${newConstructed},
         "floors":${newFloors},
         "apartments":${newApartments},
         "residentialArea":${newResidentialArea},
         "commercialArea":${newCommercialArea},
         "apartmentArea":${newApartmentArea},
         "loanperiod":${estateData.loanPeriod},
         "rate":${estateData.rate},
         "sewer_area":${newSewer_area},
         "sewer_renovated":${newSewer_renovated},
         "sewer_plannedRenovation":${newSewer_plannedRenovation},
         "sewer_rPrice":${newSewer_rPrice},
         "sewer_rRPrice":${newSewer_rRPrice},
         "plumbing_area":${newPlumbing_area},
         "plumbing_renovated":${newPlumbing_renovated},
         "plumbing_plannedRenovation":${newPlumbing_plannedRenovation},
         "plumbing_rPrice":${newPlumbing_rPrice},
         "plumbing_rRPrice":${newPlumbing_rRPrice},
         "bathrooms":${newBathrooms},
         "bathroom_renovated":${newBathroom_renovated},
         "bathroom_plannedRenovation":${newBathroom_plannedRenovation},
         "bathroom_price":${newBathroom_price},
         "bathroom_rRPrice":${newBathroom_rRPrice},
         "heating_area":${newHeating_area},
         "heating_renovated":${newHeating_renovated},
         "heating_plannedRenovation":${newHeating_plannedRenovation},
         "heating_rPrice":${newHeating_rPrice},
         "heating_rRPrice":${newHeating_rRPrice},
         "elec_area":${newElec_area},
         "elec_renovated":${newElec_renovated},
         "elec_plannedRenovation":${newElec_plannedRenovation},
         "elec_rPrice":${newElec_rPrice},
         "elec_rRPrice":${newElec_rRPrice},
         "vent_area":${newVent_area},
         "vent_renovated":${newVent_renovated},
         "vent_plannedRenovation":${newVent_plannedRenovation},
         "vent_rPrice":${newVent_rPrice},
         "vent_rRPrice":${newVent_rRPrice},
         "front_area":${newFront_area},
         "front_renovated":${newFront_renovated},
         "front_plannedRenovation":${newFront_plannedRenovation},
         "front_rPrice":${newFront_rPrice},
         "front_rRPrice":${newFront_rRPrice},
        "windows_and_balconies_area":${newWindows_and_balconies_area},
         "windows_and_balconies_renovated":${newWindows_and_balconies_renovated},
         "windows_and_balconies_plannedRenovation":${newWindows_and_balconies_plannedRenovation},
         "windows_and_balconies_rPrice":${newWindows_and_balconies_rPrice},
         "windows_and_balconies_rRPrice":${newWindows_and_balconies_rRPrice},
         "yard_area":${calculatedYardData},
         "yard_renovated":${newYard_renovated},
         "yard_plannedRenovation":${newYard_plannedRenovation},
         "yard_price":${newYard_price},
         "yard_rRPrice":${newYard_rRPrice},
         "yard_cover_area":${newYard_cover_area},
         "yard_cover_renovated":${newYard_cover_renovated},
         "yard_cover_plannedRenovation":${newYard_cover_plannedRenovation},
         "yard_cover_price":${newYard_cover_price},
         "yard_cover_rRPrice":${newYard_cover_rRPrice},
         "roof_area":${calculatedRoofData},
         "roof_renovated":${newRoof_renovated},
         "roof_plannedRenovation":${newRoof_plannedRenovation},
         "roof_price":${newRoof_price},
         "roof_rRPrice":${newRoof_rRPrice},
         "elevators":${newElevators},
         "elevators_renovated":${newElevators_renovated},
         "elevators_plannedRenovation":${newElevators_plannedRenovation},
         "elevators_price":${newElevators_price},
         "elevators_rRPrice":${newElevators_rRPrice}
      }`;
    /* console.log(body) */
    fetch(url + "api/properties", {
      credentials: "include",
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log(res.id);
        navigate("/kiinteisto/" + res.id, { replace: true });
      });
  }
  //page loader
  if (isLoading) {
    return null;
  }
  return (
    <section className={classes.center}>
      <Form onAddEstate={addEstateHandler} defaults={defaults} />
    </section>
  );
}

export default AddEstate;

import { useEffect, useRef, useState } from "react";
import "./Login.module.css";
import Card from "../card/Card";
import { useNavigate, Link } from "react-router-dom";
import classes from "./Login.module.css";
import { url } from "../../pages/HomePage";
import eye from "../../assets/eye-icon.png";
import background from "../../assets/new-year-background.jpg";

const LoginActual = () => {
  const userRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [online, setOnline] = useState(true);

  const [forgotPass, setForgotPass] = useState(false);

  async function tryServer() {
    try {
      await fetch(url + "ping");
      setOnline(true);
    } catch (e) {
      console.log("tried");
      setOnline(false);
    }
  }

  useEffect(() => {
    tryServer();

    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        window.location.replace("/kotisivu");
      }
    });
  }, []);

  const togglePassword = () => {
    if (showPass === false) {
      setShowPass(true);
    } else if (showPass === true) {
      setShowPass(false);
    }
  };

  const passForgotten = () => {
    if (forgotPass === false) {
      setForgotPass(true);
    } else if (forgotPass === true) {
      setForgotPass(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var username = user.toLowerCase();

    fetch(url + "signin", {
      method: "post",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((response) => {
      if (response.status === 401) {
        setPassError(true);
      } else if (response.status !== 401) {
        navigate("/kotisivu", { replace: true });
      }
      return response;
    });
  };

  return (
    <div
      style={{
        backgroundImage:
            `url(${background}))`,
          //"url(https://asuntosatama.fi/wp-content/uploads/2021/05/Asuntosatama_H1_Helsinki_1900x900px_v2-2.jpg)",
        backgroundSize: "cover",
      }}
    >
      {online && (
        <div className={classes.center}>
          <Card>
            <div style={{ padding: "10%" }}>
              <h1 style={{ paddingBottom: "15%" }}>Korjausvelkalaskuri</h1>
              <h5>Kirjaudu sisään</h5>
              {passError && (
                <p style={{ color: "red" }}>
                  Salasana tai käyttäjänimi on väärin
                </p>
              )}
              <form onSubmit={handleSubmit}>
                <label htmlFor="username">Käyttäjänimi:</label>
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  style={{ height: "2rem", marginTop: "1%" }}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />

                <label htmlFor="password" style={{ paddingBottom: "1%" }}>
                  Salasana:
                </label>
                <div className={classes.inputWithButton}>
                  <input
                    type={showPass ? "text" : "password"}
                    id="password"
                    style={{ height: "2rem" }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                  <button
                    className={classes.showPassButton}
                    onMouseDown={() => togglePassword()}
                    style={{ margin: "0" }}
                    type="button"
                  >
                    <img
                      src={eye}
                      alt="prop"
                      style={{
                        height: "25px",
                        paddingLeft: "0.5%",
                        opacity: "0.8",
                      }}
                    />
                  </button>
                </div>
                <div>
                  <p style={{ textAlign: "start", cursor: 'pointer', color: "#0d6efd", textDecoration: "underline" }} onClick={() => passForgotten()}>
                    Unohditko salasanasi?
                  </p>
                </div>
                {forgotPass ? <p>Ota yhteyttä: <a href="mailto: korjausvelkalaskuri@asuntosatama.fi">korjausvelkalaskuri@asuntosatama.fi</a></p> : null}
                <button className={classes.button} onClick={() => { setUser(user); handleSubmit() }}>
                  Kirjaudu sisään
                </button>
              </form>
              <div style={{ display: "grid" }}>
                <p>
                  <br />
                  Luomalla uuden käyttäjän hyväksyt{" "}
                  <Link to="/path/tos">käyttöehdot</Link>. <br />
                  Kirjautuessa tiedostat <Link to="/path/tos">
                    käyttöehdot
                  </Link>{" "}
                  ja{" "}
                  <Link to="/path/tietosuoja">
                    rekisteri- ja tietosuojaselosteen
                  </Link>
                  .
                </p>
              </div>
            </div>
          </Card>
        </div>
      )}
      {!online && (
        <div className={classes.center}>
          <Card>
            <div style={{ padding: "5%" }}>
              <h1>
                <center>Huoltokatko</center>
              </h1>
              <p>
                Hei Korjausvelkalaskuri®:n käyttäjä, valitettavasti nyt on
                käynnissä huoltokatko ja palvelu ei toimi. <br />
                Mikäli teillä on kysyttävää huoltokatkosta tai sen pituudesta,{" "}
                <a href="https://korjausvelkalaskuri.fi/lkv-tiimi/">
                  ota yhteyttä
                </a>
                !
              </p>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default LoginActual;

//Idea of this page is to let users to edit their accounts
import Card from "../components/card/Card";
import { url } from "./HomePage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import returnArrow from "../assets/returnArrow.png";
import classes from "./ViewAccount.module.css";

export default function EditAccount() {
  const navigate = useNavigate();
  const [userData, setUser] = useState("");
  const [org, setOrg] = useState(0);
  const nameInput = useRef();
  const [out, setOut] = useState("");

  //this useEffect checks which account you are logged and which organization you belong to
  useEffect(() => {
    console.log(url + "me");
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });

    fetch(url + "me/org", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrg(data.organization);
      });
  }, []);

  //this function sends put request and formats the data
  const handleEdit = async (e) => {
    e.preventDefault();

    const name = nameInput.current.value;
    const username = userData.username;

    fetch(url + `users/edit/${username}`, {
      method: "put",
      credentials: "include",
      body: JSON.stringify({ name, username, org }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 401) {
        setOut("Sinulla ei ole lupaa tehdä tätä");
        return response;
      } else if (!response.ok) {
        setOut("Muokkaus epäonnistui");
        return response;
      }
      navigate("/");
    });
  };

  return (
    <Card>
      <div className={classes.editdiv}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={returnArrow}
            alt="takaisin"
            style={{ height: "40px", cursor: "pointer", marginRight: "2.5%" }}
            onClick={() => window.history.back()}
          ></img>
          <div>
            <h2>
              <center>Muokkaa tiliä</center>
            </h2>
            <h4>
              <center>{userData.username}</center>
            </h4>
          </div>
          <div></div>
        </div>
        <form onSubmit={handleEdit}>
          <div>
            <label>Etu- ja sukunimi:</label>
            <input
              type="text"
              defaultValue={userData.name}
              ref={nameInput}
              className={classes.inputtext}
            />
          </div>
          <input
            type="submit"
            value="Tallenna muutokset"
            className={classes.inputsubmit}
          />
        </form>
        <p style={{ color: "red" }}>{out}</p>
      </div>
    </Card>
  );
}

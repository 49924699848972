//Idea of this page is to send bug report to our support email
import React, { useEffect, useState } from 'react'
import classes from "./ReportBug.module.css";
import { url } from './HomePage';

function ReportBug() {
  const [Data, setData] = useState("");

  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      setData(data);
    });
  }, []);
  return (
    <div className={classes.center}>
      <div style={{ margin: "2.5%", padding: "2.5%", width: "500px", background: "white", borderRadius: "20px", boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)" }}>
        <h1>Löysitkö ongelman?</h1>
        <br />
        <form action='https://formsubmit.co/korjausvelkalaskuri@asuntosatama.fi' method='POST' enctype="multipart/form-data" onSubmit={() => document.getElementById("sendButton").disabled = true}>
          <input hidden name='Nimi' autoComplete='off' defaultValue={Data.username} type="text" style={{ width: "100%" }} />
          <input name='Otsikko' autoComplete='off' placeholder='Otsikko' type="text" style={{ width: "100%" }} />
          <br />
          <br />
          <textarea name="Viesti" autoComplete='off' placeholder='Kuvaile ongelmaa' type="text" rows={10} style={{ resize: 'none', width: "100%", borderRadius: "5px" }}></textarea>
          <input type="hidden" name="_template" value="box"></input>
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_next" value="https://korjausvelkalaskuri.app/kotisivu" />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <input type="file" name="attachment" accept="image/png, image/jpeg"></input>
            <button type='submit' id='sendButton' style={{ marginLeft: "0" }}>Lähetä</button>
          </div>
        </form>
        <p>Ongelman tarkempi kuvailu sähköpostiimme. <br/><a href='mailto: korjausvelkalaskuri@asuntosatama.fi'>korjausvelkalaskuri@asuntosatama.fi</a></p>
      </div>
    </div>
  )
}

export default ReportBug
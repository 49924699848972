import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { url } from "../../pages/HomePage";
import Chart from "react-apexcharts";

const DebtChart = () => {
  const params = useParams();
  var date = new Date().getFullYear();
  const [chartData, setChartData] = useState([]);
  const [options, setObject] = useState({
    chart: {
      id: "korjausvelka",
      stacked: true,
      /* toolbar: {
        show: false
      } */
    },
    legend: {
      fontSize: "16px",
      fontWeight: "200",
    },
    tooltip: {
      inverseOrder: true,
    },
    yaxis: {
      tickAmount: 8,
      decimalsInFloat: 0,
      categories: [],
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: "200",
        },
      },
    },
    stroke: {
      curve: "straight",
      show: "false",
      width: "0",
    },
    fill: {
      type: "solid",
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "#203864",
      "#2f5597",
      "#7093d2",
      "#9db6df",
      "#c3d1eb",
      "#fff6db",
      "#c55a11",
      "#d46519", // uus väri
      "#fa8839",
      "#f4b183",
      "#f8cbad",
      "#fbe5d6",
      "#d9d9d9",
    ],
  });
  const [series, setSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);

  useEffect(() => {
    var yearList = [...Array(date + 21).keys()].splice(date);
    fetch(url + "api/properties/" + params.id, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChartData(data);
        //Viemäriputket
        const oldSewerData = data.sewer_maintenanceDebtPerSqM + "";
        let newSewerData = oldSewerData.replace("[", "").replace("]", "");
        newSewerData = newSewerData.split(",");
        newSewerData = newSewerData.map(Number);
        //Käyttövesiputket
        const oldPlumbingData = data.plumbing_maintenanceDebtPerSqM + "";
        let newPlumbingData = oldPlumbingData.replace("[", "").replace("]", "");
        newPlumbingData = newPlumbingData.split(",");
        newPlumbingData = newPlumbingData.map(Number);
        //Märkätilat
        const oldBathroomData = data.bathroom_maintenanceDebtPerSqM + "";
        let newBathroomData = oldBathroomData.replace("[", "").replace("]", "");
        newBathroomData = newBathroomData.split(",");
        newBathroomData = newBathroomData.map(Number);
        //Lämmitysputkisto ja -varaaja
        const oldHeatingData = data.heating_maintenanceDebtPerSqM + "";
        let newHeatingData = oldHeatingData.replace("[", "").replace("]", "");
        newHeatingData = newHeatingData.split(",");
        newHeatingData = newHeatingData.map(Number);
        //Sähkö- ja telejärjestelmä
        const oldElecData = data.elec_maintenanceDebtPerSqM + "";
        let newElecData = oldElecData.replace("[", "").replace("]", "");
        newElecData = newElecData.split(",");
        newElecData = newElecData.map(Number);
        //Ilmanvaihto, hormit
        const oldVentData = data.vent_maintenanceDebtPerSqM + "";
        let newVentData = oldVentData.replace("[", "").replace("]", "");
        newVentData = newVentData.split(",");
        newVentData = newVentData.map(Number);
          //Julkisivut
         const oldFrontData = data.front_maintenanceDebtPerSqM + "";
         let newFrontData = oldFrontData.replace("[", "").replace("]", "");
         newFrontData = newFrontData.split(",");
         newFrontData = newFrontData.map(Number);
        //Ikkunat ja parvekkeet
        const oldWindows_and_balconiesData = data.windows_and_balconies_maintenanceDebtPerSqM + "";
        let newWindows_and_balconiesData = oldWindows_and_balconiesData.replace("[", "").replace("]", "");
        newWindows_and_balconiesData = newWindows_and_balconiesData.split(",");
        newWindows_and_balconiesData = newWindows_and_balconiesData.map(Number);  
        //Salaojat ja sokkeli
        const oldYardData = data.yard_maintenanceDebtPerSqM + "";
        let newYardData = oldYardData.replace("[", "").replace("]", "");
        newYardData = newYardData.split(",");
        newYardData = newYardData.map(Number);
        //Pihakansi
        const oldYardCoverData = data.yard_cover_maintenanceDebtPerSqm + "";
        console.log(data.yard_cover_maintenanceDebtPerSqm + "");

        let newYardCoverData = oldYardCoverData
          .replace("[", "")
          .replace("]", "");
        newYardCoverData = newYardCoverData.split(",");
        newYardCoverData = newYardCoverData.map(Number);
        //Katto
        const oldRoofData = data.roof_maintenanceDebtPerSqM + "";
        let newRoofData = oldRoofData.replace("[", "").replace("]", "");
        newRoofData = newRoofData.split(",");
        newRoofData = newRoofData.map(Number);
        //Hissi
        const oldElevatorsData = data.elevators_maintenanceDebtPerSqM + "";
        let newElevatorsData = oldElevatorsData
          .replace("[", "")
          .replace("]", "");
        newElevatorsData = newElevatorsData.split(",");
        newElevatorsData = newElevatorsData.map(Number);
        //Talotekniikan kokonaisarvo
        const oldTotalData = data.total_rRPrice + "";
        let newTotalData = oldTotalData.replace("[", "").replace("]", "");
        newTotalData = newTotalData.split(",");
        newTotalData = newTotalData.map(Number);

        const renovations = data.renovations.split(",");

        let annotations = {
          xaxis: [],
        };
        if (renovations[0] !== "") {
          for (let i = 0; i < 20; i++) {
            if (
              renovations[i] !== "0.00"
            ) {
              // Note the string comparison here
              annotations.xaxis.push({
                x: yearList[i],
                borderColor: "#775DD0",
                label: {
                  orientation: "horizontal",
                  style: {
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 200,
                  },
                  text: Math.round(renovations[i]) + "€",
                },
              });
            }
          }
        }

        console.log("Yard cover", oldYardCoverData);

        setObject({
          chart: {
            id: "Viemäriputket",
          },
          xaxis: {
            categories: [...yearList],
            labels: {
              style: {
                fontSize: "16px",
                fontWeight: "200",
              },
            },
          },
          annotations: annotations,
        });
        setSeries([
          {
            name: "Viemäriputket",
            data: newSewerData,
          },
          {
            name: "Käyttövesiputket",
            data: newPlumbingData,
          },
          {
            name: "Märkätilat",
            data: newBathroomData,
          },
          {
            name: "Lämmitysputkisto",
            data: newHeatingData,
          },
          {
            name: "Sähkö- ja telejärjestelmä",
            data: newElecData,
          },
          {
            name: "Ilmanvaihto, hormit",
            data: newVentData,
          },
          {
            name: "Julkisivut",
            data: newFrontData,
          },
          {
            name: "Ikkunat ja parvekkeet",
            data: newWindows_and_balconiesData,
          },
          {
            name: "Salaojat ja sokkeli",
            data: newYardData,
          },
          {
            name: "Pihakansi",
            data: newYardCoverData,
          },
          {
            name: "Katto",
            data: newRoofData,
          },
          {
            name: "Hissi",
            data: newElevatorsData,
          },
          {
            name: "Talotekniikan kokonaisarvo",
            data: newTotalData,
          },
        ]);
      });
  }, [params.id, date, chartData.yard_cover_maintenanceDebtPerSqM]);

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width={"100%"}
      height={500}
    />
  );
};

export default DebtChart;

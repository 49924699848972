//Idea of this page is to inform user that he has deleted his account succesfully
import Card from "../components/card/Card"; 
import { url } from "./HomePage";
import { useState, useEffect } from "react";

export default function SuccessfullyDeleted() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch(
            url + "me",
            {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.status === 401) {
                    console.log("token invalid")
                    window.location.href = "/";
                }
                return setIsLoading(false);
            }
            )
    }, [])
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <Card>
            <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                <h1>Olet poistanut käyttäjäsi</h1>
                <p>
                    Käyttäjäsi on poistettu. Kiitos paljon ohjelman käytöstä.
                </p>
            </div>
        </Card>
    )
}
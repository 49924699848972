import React, { useState, useEffect, useRef } from "react";
import { heroku, domain } from "../../App";
import { FaTimesCircle } from "react-icons/fa";

const inputfieldStyle = {
  border: "1px solid rgb(204, 204, 204)",
  borderRadius: "5px",
  padding: "5px",
  outline: "none",
  maxHeight: "200px",
};

const OtherProducts = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    desc: "",
    link: "",
  });

  const [editRealtor, setEditRealtor] = useState(undefined);
  const [selectedRealtorData, setSelectedRealtorData] = useState();
  const [realtorData, setRealtorData] = useState([]);
  const [selectedRealtorIndex, setSelectedRealtorIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    }
  };
  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e, file) => {
    e.preventDefault();
    let data;
    try {
      const response = await fetch(
        "https://korjausvelkalaskuri.app:8000/api/store/realtor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      data = await response.json();

      if (response.status === 200) {
        console.log("Realtor added successfully!");
        setFormData({
          name: "",
          company: "",
          phone: "",
          email: "",
          desc: "",
          link: "",
        });
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while adding realtor:", error);
    }

    try {
      const secondFormData = new FormData();
      secondFormData.append("pic", file);
      await fetch(domain + `/api/store/realtor/photo/${data.uuid}`, {
        method: "POST",
        credentials: "include",
        body: secondFormData,
      });
      fetchRealtorData();
    } catch (error) {
      console.error("Error occurred while adding product image:", error);
    }
  };

  const handleEditSubmit = async (e) => {
  
    e.preventDefault();
    try {
      formData["uuid"] = selectedRealtorData.uuid;

      for (let key in formData) {
        if (formData[key] === "") {
          formData[key] = selectedRealtorData[key];
        }
      }

      const response = await fetch(
        "https://korjausvelkalaskuri.app:8000/api/store/realtor/update",
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        console.log("Realtor edited successfully!");
        setFormData({
          name: "",
          company: "",
          phone: "",
          email: "",
          desc: "",
          link: "",
        });
        fetchRealtorData();
        setEditRealtor(undefined);
        setSelectedRealtorIndex(null);
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while adding realtor:", error);
    }
  };

  const fetchRealtorData = async () => {
    try {
      const response = await fetch(heroku + "/api/kvlist/get");
      if (response.status === 202) {
        const data = await response.json();
        if (data != null) {
          setRealtorData(data);
        } else {
          setRealtorData([]);
        }
      } else {
        console.error("Failed to fetch realtor data");
      }
    } catch (error) {
      console.error("Error occurred while fetching realtor data:", error);
    }
  };

  const handleDeleteProduct = async (realtor) => {
    try {
      const response = await fetch(
        `https://korjausvelkalaskuri.app:8000/api/store/realtor/remove`,
        {
          method: "DELETE",
          credentials: "include",
          body: JSON.stringify(realtor),
        }
      );
   
      if (response.status === 200) {
        fetchRealtorData();
        console.log("Product deleted successfully!");
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while deleting product:", error);
    }
  };

  useEffect(() => {
    fetchRealtorData();
  }, []);

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "80vh",
        padding: "5%",
      }}
    >
      <h1 style={{ fontSize: "28px" }}>Välittäjien hallinta</h1>
      <div style={{ display: "flex", height: "100%" }}>
        {editRealtor ? (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleEditSubmit(e)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Välittäjän nimi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="name"
                  defaultValue={selectedRealtorData.name}
                  key={selectedRealtorData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label style={{ fontWeight: "bold" }}>Yritys</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="company"
                  defaultValue={selectedRealtorData.company}
                  key={selectedRealtorData.company}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kuvaus</label>
                <textarea
                  style={inputfieldStyle}
                  name="desc"
                  defaultValue={selectedRealtorData.desc}
                  key={selectedRealtorData.desc}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Puhelinnumero</label>
                <input
                  style={inputfieldStyle}
                  type="tel"
                  name="phone"
                  defaultValue={selectedRealtorData.phone}
                  key={selectedRealtorData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Sähköposti</label>
                <input
                  style={inputfieldStyle}
                  type="email"
                  name="email"
                  defaultValue={selectedRealtorData.email}
                  key={selectedRealtorData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Link</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="link"
                  defaultValue={selectedRealtorData.link}
                  key={selectedRealtorData.link}
                  onChange={handleChange}
                />
              </div>
              <button type="submit">Muokkaa</button>
            </form>
          </div>
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleSubmit(e, selectedFile)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Välittäjän nimi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label style={{ fontWeight: "bold" }}>Yritys</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kuvaus</label>
                <textarea
                  style={inputfieldStyle}
                  name="desc"
                  value={formData.desc}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Puhelinnumero</label>
                <input
                  style={inputfieldStyle}
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Sähköposti</label>
                <input
                  style={inputfieldStyle}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Kuva</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <input
                      type="file"
                      accept=".png"
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      style={{ color: "transparent", width: "100px" }}
                    />
                    {selectedFile && (
                      <div>
                        {selectedFile.name}
                        <FaTimesCircle
                          onClick={handleDeleteFile}
                          style={{ paddingLeft: "5px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Link</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "end",
                }}
              >
                <button type="submit" style={{ margin: "0px" }}>
                  Luo
                </button>
              </div>
            </form>
          </div>
        )}

        <div
          style={{
            width: "50%",
            height: "100%",
            overflow: "scroll",
            listStyle: "none",
            boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {realtorData.map((realtor, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                background: "rgb(241, 241, 241)",
                borderBottom: "2px solid rgb(204, 204, 204)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={heroku + `/static/uploads/images/kvl/${realtor.uuid}.png`}
                alt="kuva"
                style={{
                  height: "150px",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  marginRight: "20px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "4px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "20px",
                      margin: "0",
                      paddingBottom: "3px",
                    }}
                  >
                    {realtor.name}
                  </h2>
                  <h2 style={{ fontSize: "16px", margin: "0" }}>
                    {realtor.company}
                  </h2>
                  <text>{realtor.desc}</text>
                </div>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      height: "35px",
                      fontSize: "14px",
                      borderColor:
                        selectedRealtorIndex === index ? "#007ab6" : "",
                    }}
                    onClick={(e) => {
                      if (editRealtor === e.target) {
                        setEditRealtor(undefined);
                      } else {
                        setEditRealtor(e.target);
                      }
                      setSelectedRealtorData(realtor);
                      setSelectedRealtorIndex(
                        selectedRealtorIndex === index ? null : index
                      );
                    }}
                  >
                    Muokkaa
                  </button>

                  <button
                    style={{ height: "35px", fontSize: "14px" }}
                    onClick={() => handleDeleteProduct(realtor)}
                  >
                    Poista
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherProducts;

//Idea of this page is to check if there are already a existing estate
//this prevents double estates to your organisation
import React, { useEffect, useState } from 'react'
import Card from '../components/card/Card'
import classes from "./CheckIfExist.module.css";
import x_icon from "../assets/x-icon.png";
import { url } from "../pages/HomePage";
import { Link, useNavigate } from "react-router-dom";

function CheckIfExist() {
  const [publicData, setPublicData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [ifSearchMatch, setIfSearchMatch] = useState(true);

  var estateList = [];

  const navigate = useNavigate();

  //this useEffect listens to the searchTerm and even if the searchTerm is triggered several times, this useEffect only reads its triggering once
  useEffect(() => {
    const timeout = setTimeout(() => { checkIfContains() }, 1200);
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  //pulls all data from backend and checks our rights to be on this page
  useEffect(() => {
    fetch(
      url + "api/properties",
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response.status === 401) {
          console.log("token invalid")
          window.location.href = "/";
        }
        setIsLoading(false);
        return response.json()
      })
      .then((data) => {
        const estates = [];
        for (const key in data) {
          const estate = {
            id: key,
            ...data[key],
          };

          estates.unshift(estate);
        }
        setPublicData(estates)
      })
  }, [])

  //this function checks if you are creating duplicate
  function checkIfContains() {
    if (searchTerm === "") {
      setIfSearchMatch(true);
    } else {
      for (var i = 0; i < estateList.length; i++) {
        var newEstateList = estateList[i].name + ""
        if (searchTerm.includes("asunto") || searchTerm.includes("ASUNTO") || searchTerm.includes("Asunto") || searchTerm.includes("asunto.") || searchTerm.includes("ASUNTO.") || searchTerm.includes("Asunto.") || searchTerm.includes("as") || searchTerm.includes("AS") || searchTerm.includes("As") || searchTerm.includes("as.") || searchTerm.includes("AS.") || searchTerm.includes("As.")) {
          const newSearch = searchTerm.replace(/[^\s]*/, "")
          console.log("search: ", newSearch)
          if (newEstateList.includes("asunto") || newEstateList.includes("ASUNTO") || newEstateList.includes("Asunto") || newEstateList.includes("asunto.") || newEstateList.includes("ASUNTO.") || newEstateList.includes("Asunto.") || newEstateList.includes("as") || newEstateList.includes("AS") || newEstateList.includes("As") || newEstateList.includes("as.") || newEstateList.includes("AS.") || newEstateList.includes("As.")) {
            const newEstate = newEstateList.replace(/[^\s]*/, "")
            console.log("estate: ", newEstate)
            if (newEstate.toLowerCase() === newSearch.toLowerCase()) {
              console.log("toimii")
              console.log(estateList.length)
              setIfSearchMatch(true);
            } else {
              setIfSearchMatch(false);
            }
          }
        } else {
          for (var q = 0; q < estateList.length; q++) {
            /* var newEstateList = estateList[i].name + "" */
            if (newEstateList.toLowerCase() === searchTerm.toLowerCase()) {
              setIfSearchMatch(true);
            } else {
              setIfSearchMatch(false);
            }
          }
        }
      }
      if (estateList.length === 0) {
        setIfSearchMatch(false);
      }
    }
  }
  if (isLoading) {
    return (
      null
    );
  }
  return (
    <div>
      <div className={classes.center}>
        <Card>
          <div className={classes.content}>
            <div className={classes.row}>
              <h3 style={{ margin: "0" }}>Lisää kiinteistö</h3>
              <img src={x_icon} alt="takaisin" style={{ height: "20px", cursor: "pointer" }} onClick={() => window.history.back()}></img>
            </div>
            <br />
            <form>
              <div className={classes.row}>
                <input placeholder='Taloyhtiön nimi' onChange={event => setSearchTerm(event.target.value)} style={{ width: "12rem", height: "100%", border: 0, borderRadius: "0px" }}></input>
                {ifSearchMatch ? <></> : <button
                  style={{
                    margin: "0",
                    padding: "7px",
                    fontSize: "12px",
                    borderRadius: "0px",
                    border: "0"
                  }}
                  onClick={() => navigate(`/lisaakiinteisto?name=${searchTerm}`)}
                >Luo kiinteistö</button>}
              </div>
            </form>
            <br />
            <section className={classes.items}>
              <h6>Vastaavia kiinteistöjä:</h6>
              {publicData.filter((estate) => { //this script checks all variations of your estate and returns all estates names that are similar
                if (searchTerm === "") {
                  return null;
                } else if (
                  searchTerm.includes("asunto") || searchTerm.includes("ASUNTO") || searchTerm.includes("Asunto") || searchTerm.includes("asunto.") || searchTerm.includes("ASUNTO.") || searchTerm.includes("Asunto.") || searchTerm.includes("as") || searchTerm.includes("AS") || searchTerm.includes("As") || searchTerm.includes("as.") || searchTerm.includes("AS.") || searchTerm.includes("As.")
                ) {
                  const newSearch = searchTerm.replace(/[^\s]*/, "") //if some of those words above are included on your search this script will replace them with empty strings
                  if (estate.name.toLowerCase().includes(newSearch.toLowerCase()) === {}) {
                    setIfSearchMatch(false);
                  }
                  return estate.name.toLowerCase().includes(newSearch.toLowerCase());
                }
                return estate.name.toLowerCase().includes(searchTerm.toLowerCase());
              }).map((estate) => {
                estateList.push(estate)
                return (
                  <div key={estate.id}>
                    <Link
                      to={{ pathname: `/kiinteisto/${estate.id}` }}
                      style={{ textDecoration: "none" }}
                    >
                      {estate.name}
                    </Link>
                  </div>
                )
              })
              }
            </section>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default CheckIfExist
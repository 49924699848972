//Idea of this page is to delete your account
import Card from "../components/card/Card";
import { url } from "./HomePage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import x_icon from "../assets/x-icon.png";
import classes from "./ViewAccount.module.css";
import "./ViewAccount.module.css"

export default function DeleteUser() {
  const navigate = useNavigate();
  const pass = useRef();
  const [userData, setUser] = useState("");

  //first it checks which user you are logged in
  useEffect(() => {
    console.log(url + "me");
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });
  }, []);

  const deleteAccount = async (e) => {
    e.preventDefault();

    const username = userData.username;
    const password = pass.current.value;

    if (document.getElementById("accept1").checked
      && document.getElementById("accept2").checked);
    else {
      return;
    }

    if (window.confirm("Oletko varma, että haluat poistaa tilisi?") === true) {
      fetch(
        url + "users",
        {
          method: "delete",
          body: JSON.stringify({ username, password }),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/account/delete/success");
    }
    else {
      navigate("/account");
    }
  };

  return (
    <Card>
      <div className={classes.editdiv}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h3 style={{ color: "red" }}>Tilin poisto</h3>
          <img src={x_icon} alt="takaisin" style={{ height: "25px", cursor: "pointer" }} onClick={() => window.history.back()}></img>
        </div>
        <form onSubmit={deleteAccount}>
          <div>
            <input
              type="password"
              className={classes.inputtext}
              ref={pass}
              placeholder="Salasanasi"
            />
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              style={{ width: "15px", padding: "2.5%" }}
              id="accept1"
            />
            <div className={classes.space}></div>
            <label for="accept1">
              Hyväksyn, että käyttöoikeuteni sivustoon loppuu ja olen varma,
              että haluan poistaa tilin, jonka käyttäjänimi on "
              {userData.username}"
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              style={{ width: "15px", padding: "2.5%" }}
              id="accept2"
            />
            <div className={classes.space}></div>
            <label for="accept2">
              Olen lukenut ja hyväksynyt <a href="/tos">käyttöehdot</a> ja
              tiedän, mitä tilin poistosta seuraa.
            </label>
          </div>
          <input
            type="submit"
            value="Poista tili"
            className={classes.inputdelete}
          />
        </form>
      </div>
    </Card>
  );
}

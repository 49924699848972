//This page will show all the things you can do to your account or to this program. Options amount depends on your rights
import Card from "../components/card/Card";
import { url } from "./HomePage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import returnArrow from "../assets/returnArrow.png";
import classes from "./ViewAccount.module.css";

export default function ViewAccount() {
  const navigate = useNavigate();
  const [userData, setUser] = useState("");
  const [privilege, setPrivilege] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [org, setOrg] = useState("Et kuulu mihinkään organisaatioon");

  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          console.log("token invalid")
          window.location.href = "/";
        } else {
          setIsLoading(false);
        }
        return response.json()
      })
      .then((data) => {
        setUser(data);
        switch (data.privilege) {
          case 0:
            setPrivilege("Omistaja käyttäjä");
            break;
          case 1:
            setPrivilege("Admin käyttäjä");
            break;
          case 2:
            setPrivilege("Perus käyttäjä");
            break;
          default:
            break;
        }
        
        if(data.organization !== "") {
          setOrg(data.organization)
        }
      });
  }, []);

  const edit = async (e) => {
    e.preventDefault();

    navigate("/account/edit");
  };

  const editPass = async (e) => {
    e.preventDefault();

    navigate("/account/password");
  };

  const gotoDelete = async (e) => {
    e.preventDefault();

    navigate("/account/delete");
  };

  const gotoAdmin = async (e) => {
    e.preventDefault();

    navigate("/admin");
  };

  const gotoControl = async (e) => {
    e.preventDefault();

    navigate("/control");
  };

  if (isLoading) {
    return (
      null
    );
  }
  return (
    <Card>
      <div style={{ margin: "2%", paddingTop: "2.5%", paddingBottom: "2.5%", display: "grid", justifyContent: "center" }}>
        <h2>
          <center>Tilin hallinta</center>
        </h2>
        <h4>
          <center>{userData.username}</center>
        </h4>
        <h3>{userData.name}</h3>
        <h6>{privilege}</h6>
        <h6>{org}</h6>
        <div>
          <div style={{display: "flex", alignItems: "center"}}>
            <img src={returnArrow} alt="takaisin" style={{ height: "40px", cursor: "pointer" }} onClick={() => window.history.back()}></img>
            <button className={classes.button} onClick={edit}>
              Muokkaa tiliä
            </button>
            <div className={classes.space}></div>
            <button className={classes.button} onClick={editPass}>
              Vaihda salasana
            </button>
            {userData.privilege <= 1 && (
              <button className={classes.button} onClick={gotoAdmin}>
                Käyttäjien hallinta
              </button>
            )}
            {userData.privilege <= 1 && (
              <button className={classes.button} onClick={gotoControl}>
                Ohjelmiston hallinta
              </button>
            )}
            <div className={classes.space}></div>
            <button className={classes.button} onClick={gotoDelete}>
              Poista tili
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
}

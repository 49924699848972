import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { url } from "../../pages/HomePage";
import Chart from "react-apexcharts";

const SeparatedCompensationChart = () => {
  const params = useParams();
  var date = new Date().getFullYear();
  const [options, setObject] = useState({
    chart: {
      id: "separated Compensation",
      stacked: true,
      /* toolbar: {
        show: false
      } */
    },
    legend: {
      fontSize: "16px"
    },
    yaxis: {
      tickAmount: 6,
      decimalsInFloat: 0,
      categories: [],
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: "200",
        },
      },
    },
    stroke: {
      curve: "straight",
      colors: ["transparent", "#111111"],
      show: "false",
      width: "0",
    },
    fill: {
      type: "solid",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#203864", "#9db6df"],
  });
  const [series, setSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);
  useEffect(() => {
    /* var yearList = [...Array(date + 21).keys()]; */
    const yearsWithAdditionalTicks = [];
    for (let i = date; i <= date + 20; i++) {
      yearsWithAdditionalTicks.push(i);
      for (let j = 1; j <= 11; j++) {
        yearsWithAdditionalTicks.push("");
      }
    }

    fetch(url + "api/properties/" + params.id, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })

      .then((data) => {
        console.log(data);
        //Viemäriputket
        const oldCompensationData = data.compensation + "";
        let charts = oldCompensationData.split("|");
        /* let newCompensationData = charts[0].split(",") */
        let newAmortData = charts[1].split(",");
        let newRateData = charts[2].split(",");
        /* newCompensationData = newCompensationData.map(Number) */
        newAmortData = newAmortData.map(Number);
        newRateData = newRateData.map(Number);
        /* console.log("Rahoitusvastike = " + newCompensationData) */
        console.log("Lyhennys = " + newAmortData);
        console.log("Korko = " + newRateData);

        setObject({
          chart: {
            id: "rahoitusvastike",
          },
          xaxis: {
            categories: yearsWithAdditionalTicks,
            labels: {
              style: {
                fontSize: "16px",
                fontWeight: "200",
              },
            },
          },
        });
        setSeries([
          /* {
            name: "Rahoitusvastike",
            data: newCompensationData
          }, */
          {
            name: "Lyhennys",
            data: newAmortData,
          },
          {
            name: "Korko",
            data: newRateData,
          },
        ]);
      });
  }, [params.id, date]);

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width={"100%"}
      height={500}
    />
  );
};

export default SeparatedCompensationChart;

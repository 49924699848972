import React from "react";
import { domain } from "../../App";

function Requests() {
  const [dummyData, setDummyData] = React.useState([]);

  async function fetchRequests() {
    try {
      const response = await fetch(domain + "/api/store/certs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);

        // turn data obj into array
        let arr = [];
        for (let key in data) {
          arr.push(data[key]);
        }

        setDummyData(arr);
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error occurred while fetching products:", error);
    }
  }

  async function deleteRequest(uuid) {
    try {
      const response = await fetch(domain + "/api/store/certs/del/" + uuid, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        console.log("Request deleted");
        fetchRequests();
      } else {
        console.error("Failed to delete request");
      }
    } catch (error) {
      console.error("Error occurred while deleting request:", error);
    }
  }

  React.useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "70vh",
        padding: "5%",
        marginBottom: "40px",
      }}
    >
      <h1 style={{ fontSize: "28px", marginBottom: "10px" }}>
        Isännöitsijätodistus pyynnöt
      </h1>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          background: "white",
          borderRadius: "10px",
          boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
          padding: "20px",
        }}
      >
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ fontSize: "16px" }}>Kiinteistö</th>
              <th style={{ fontSize: "16px" }}>Nimi</th>
          {/*     <th style={{ fontSize: "16px" }}>Sukunimi</th> */}
              <th style={{ fontSize: "16px" }}>Sähköposti</th>
              <th style={{ fontSize: "16px" }}>Vastaanotettu</th>
              <th style={{ fontSize: "16px" }}>Käsitelty</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((cert, index) => (
              <tr key={index}>
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {cert.propname}
                </td>
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {cert.fname}
                </td>
        {/*         <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {cert.lname}
                </td> */}
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {cert.email}
                </td>
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {new Date(cert.date).toLocaleDateString("fi-FI", {
                    timeZone: "Europe/Helsinki",
                  })}
                </td>
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  {cert.status ? "Kyllä" : "Ei"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid rgb(204, 204, 204)",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      onClick={async () => {
                        const url =
                          domain + "/api/store/certs/accept/" + cert.uuid;
                        const response = await fetch(url, {
                          method: "POST",
                          credentials: "include",
                        });

                        if (!response.ok) {
                          throw new Error("Network response was not ok");
                        }

                        // Call fetchRequests() to run it again
                        fetchRequests();
                      }}
                    >
                      Lähetä
                    </button>

                    <button
                      onClick={async () => {
                        const url =
                          domain + "/api/store/certs/pdf/" + cert.uuid;
                        const response = await fetch(url, {
                          method: "GET",
                          credentials: "include",
                        });

                        if (!response.ok) {
                          throw new Error("Network response was not ok");
                        }

                        const blob = await response.blob();
                        const objectUrl = URL.createObjectURL(blob);

                        window.open(objectUrl, "_blank");

                        // Call fetchRequests() to run it again
                        fetchRequests();
                      }}
                    >
                      Lataa pdf
                    </button>

                    <button
                      onClick={() => {
                        deleteRequest(cert.uuid);
                      }}
                    >
                      Poista
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Requests;

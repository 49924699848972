//Idea of this page is to give credits for all the libraries and packages that we have used
import Card from "../components/card/Card";
import { url } from "./HomePage";
import { useState, useEffect } from "react";

export default function Credits() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch(
            url + "me",
            {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.status === 401) {
                    console.log("token invalid")
                    window.location.href = "/";
                }
                return setIsLoading(false);
            }
            )
    }, [])
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <Card>
            <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                <div>
                    <h3>Tunnustukset</h3>
                    <p>
                        Korjausvelkalaskurin tiimi on pystynyt rakentaa tämän ohjelmiston käyttäen avoimen lähdekoodin kirjastoja ja ohjelmia käyttäen.
                        Kiitämme kaikkia, jotka ovat osallistuneet tässä mainittuihin projekteihin.
                    </p>
                    <p>
                        Lista ei ole välttämättä ajan tasalla, jos huomaat, että käytämme avoimen lähdekoodin kirjastoasi/ohjelmaasi, <a href="/yhteystiedot">ota yhteyttä</a>.
                    </p>
                </div>
                <div>
                    <h5>Frontend</h5>
                    <p>
                        korjausvelkalaskuri.app:in frontend käyttää seuraavia kirjastoja:
                    </p>
                    <ul>
                        <li>
                            <a href="https://github.com/facebook/react">React</a>, Facebook Inc., <a href="https://github.com/facebook/react/blob/main/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/remix-run/react-router">React Router</a>, Remix Software, <a href="https://github.com/remix-run/react-router/blob/main/LICENSE.md">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/apexcharts/apexcharts.js">Apexcharts</a>, Apexcharts, <a href="https://github.com/apexcharts/apexcharts.js/blob/main/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/apexcharts/react-apexcharts">React Apexcharts</a>, Apexcharts, <a href="https://github.com/apexcharts/react-apexcharts/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h5>Backend</h5>
                    <p>
                        korjausvelkalaskuri.app:in backend on ohjelmoitu Go kielellä. <br></br>
                        korjausvelkalaskuri.app:in backend käyttää seuraavia kirjastoja:
                    </p>
                    <ul>
                        <li>
                            <a href="https://github.com/gin-gonic/gin">Gin Web Framework</a>, Manuel Martinez-Almeida, <a href="https://github.com/gin-gonic/gin/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/gin-contrib/sse">Server-Sent Events</a>, Manuel Martinez-Almeida, <a href="https://github.com/gin-contrib/sse/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/gin-contrib/secure">Secure</a>, gin-contrib, <a href="https://github.com/gin-contrib/secure/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/gin-contrib/cors">CORS gin's middleware</a>, Gin-Gonic, <a href="https://github.com/gin-contrib/cors/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/blockloop/scan">Scan</a>, Brett, <a href="https://github.com/blockloop/scans/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/go-playground/locales">locales</a>, Go Playground, <a href="https://github.com/go-playground/locales/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/go-playground/universal-translator">universal-translator</a>, Go Playground, <a href="https://github.com/go-playground/universal-translator/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/go-playground/validator">Package validator</a>, Dean Karn, <a href="https://github.com/go-playground/validator/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/goccy/go-json">go-json</a>, Masaaki Goshima, <a href="https://github.com/goccy/go-json/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/google/uuid">uuid</a>, Google Inc., <a href="https://github.com/google/uuid/blob/master/LICENSE">BSD 3-ehdon lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/json-iterator/go">jsoniter</a>, json-iterator, <a href="https://github.com/json-iterator/go/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/leodido/go-urn">go-urn</a>, Leonardo Di Donato, <a href="https://github.com/leodido/go-urn/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/lib/pq">pq</a>, Blake Mizerany, <a href="https://github.com/lib/pq/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/mattn/go-isatty">go-isatty</a>, Yasuhirio Matsumoto, <a href="https://github.com/mattn/go-isatty/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/modern-go/concurrent">concurrent</a>, modern-go tiimi, <a href="https://github.com/modern-go/concurrent/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/modern-go/reflect2">reflect2</a>, modern-go tiimi, <a href="https://github.com/modern-go/reflect2/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/mohae/deepcopy">deepCopy</a>, Joel, <a href="https://github.com/mohae/deepcopy/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/pelletier/go-toml">go-toml</a>, Thomas Pelletier ja Eric Anderton, <a href="https://github.com/pelletier/go-toml/blob/master/LICENSE">MIT-lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/richardlehane/mscfb">mscfb</a>, Richard Lehane, <a href="https://github.com/richardlehane/mscfb/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/richardlehane/mscfb">mscfb</a>, Richard Lehane, <a href="https://github.com/richardlehane/mscfb/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/richardlehane/msoleps">msoleps</a>, Richard Lehane, <a href="https://github.com/richardlehane/msoleps/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/ugorji/go">go-codec</a>, Ugorji Nwoke, <a href="https://github.com/ugorji/go/blob/master/LICENSE">Apache-2.0 lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/xuri/efp">Excel Formula Parser</a>, Ri Xu, <a href="https://github.com/xuri/efp/blob/master/LICENSE">BSD 3-ehdon lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/xuri/efp">Excel Formula Parser</a>, Ri Xu, <a href="https://github.com/xuri/efp/blob/master/LICENSE">BSD 3-ehdon lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/xuri/nfp">Number Format Parser</a>, Ri Xu, <a href="https://github.com/xuri/nfp/blob/master/LICENSE">BSD 3-ehdon lisenssi</a>
                        </li>
                        <li>
                            <a href="https://github.com/qax-os/excelize">Excelize</a>, Excelizen kehittäjät, <a href="https://github.com/xuri/nfp/blob/master/LICENSE">BSD 3-ehdon lisenssi</a>
                        </li>
                    </ul>
                </div>
            </div>
        </Card>
    )
}
//Idea of this page is to inform our services terms
import { useState, useEffect } from "react"; 
import Card from "../components/card/Card";
import { url } from "./HomePage";

export default function TermsOfService() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch(
            url + "me",
            {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (window.location.pathname === "/path/tos"){
                    return setIsLoading(false);
                }
                if (response.status === 401) {
                    console.log("token invalid")
                    window.location.href = "/";
                }
                return setIsLoading(false);
            }
            )
    }, [])
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <Card>
            <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                <h3>Käyttöehdot</h3>
                <div>
                    <p>
                        Nämä käyttöehdot koskevat Suomen Asuntosatama LKV Oy:n (jäljempänä "Palveluntarjoaja") palvelua Korjausvelkalaskuria (jäljempänä "Palvelu").
                    </p>
                    <p>
                        Luodessa uuden käyttäjän Palvelulle (jäljempänä "Käyttäjä") ja/tai ostaessa Palvelun asiakas hyväksyy nämä käyttöehdot
                        sekä tiedostaa rekisteri- ja tietosuojaselosteen.
                    </p>
                </div>
                <div>
                    <h5>Vastuunrajoitus</h5>
                    <p>
                        Palveluntarjoaja tarjoaa Palvelun ja sen sisällön "sellaisenaan kuin se on" ilman mitään takuita, vakuuksia tai vastuita.
                        Käyttäjä käyttää Palvelua omalla vastuullaan. Palveluntarjoaja ei vastaa Palvelun käyttöön liittyvistä tietoturvariskeistä tai
                        Palvelun sisällön oikeellisuudesta.
                    </p>
                    <p>
                        Käyttäjä on velvollinen korvaamaan Palveluntarjoajalle sekä kolmansille osapuolille aiheutuneen vahingon, joka on seurausta
                        Käyttäjän lain, viranomaisten määräysten ja ohjeiden tai näiden Käyttöehtojen vastaista teosta tai laiminlyönnistä.
                    </p>
                    <p>
                        Ellei pakottavasta lain säännöksestä muuta johdu, Palveluntarjoaja ei vastaa mistään välittömistä tai välillisistä vahingoista, joita
                        Käyttäjälle tai kolmansille osapuolille mahdollisesti aiheutuu Palvelun käyttämisestä tai Palvelun keskeytyksistä, viivästyksistä, virheistä
                        tai puutteista taikka tiedoista tai ilmoituksista, joita Palvelun kautta on saatavilla taikka mistä tahansa muista välittömistä tai välillisistä
                        vahingoista, jotka muutoin aiheutuvat Palvelun yhteydessä.
                    </p>
                    <p>
                        Kumpikaan osapuoli ei vastaa vahingoista, jotka johtuvat osapuolen vaikutusmahdollisuuksien ulkopuolella olevasta esteestä, jota osapuolen ei voida
                        kohtuudella olettaa ottaneen huomioon sopimuksentekohetkellä ja jonka seurauksia osapuoli ei olisi kohtuudella voinut välttää tai estää.
                    </p>
                </div>
                <div>
                    <h5>Henkilötietojen käsittely ja evästeet</h5>
                    <p>
                        Palveluntarjoaja käsittelee Käyttäjän henkilötietoja <a href="/tietosuoja">rekisteri- ja tietosuojaselosteen</a> kuvailemalla tavalla.
                    </p>
                </div>
                <div>
                    <h5>Palveluntarjoajan velvollisuudet ja oikeudet</h5>
                    <p>
                        Palvelu on pääsääntöisesti käytettävissä 24 tuntia vuorokaudessa, jokaisena vuoden päivänä. Palveluntarjoajalla on oikeus ottaa
                        Palvelun tai sen osa tilapäisesti pois käytöstä huollon, turvallisuuden, järjestelmän kuormituksen tai muun painavan syyn takia.
                        Palveluntarjoajalla on lisäksi oikeus ottaa Palvelu tai sen osa pois käytöstä pysyvästi.
                    </p>
                    <p>
                        Palveluntarjoajalla on oikeus muuttaa Palvelua miten Palveluntarjoaja kokee parhaaksi.
                    </p>
                    <p>
                        Palveluntarjoaja ei takaa Palvelun toimivuutta eikä sen käyttäjien luoman sisällön ja Palvelun ennusteiden tarkuutta.
                    </p>
                </div>
                <div>
                    <h5>Käyttäjän/Asiakkaan oikeudet ja velvollisuudet</h5>
                    <p>
                        Asiakas saa maksettuaan Palvelusta tai muuten saadun oikeuden saadessa Palveluntarjoajalta Palvelun oikeuden käyttää
                        Palvelua. Asiakas saa tällöin mahdollisuuden luoda käyttäjän Palvelulle.
                    </p>
                    <p>
                        Käyttäjä sitoutuu toimimaan Käyttöehtojen, muiden Palvelua koskevien sääntöjen, hyvän tavan ja lain mukaisesti.
                    </p>
                    <p>
                        Käyttäjä ymmärtää, että Palveluntarjoaja ei ole velvollinen valvomaan Käyttäjän Palvelun käyttöä. Kuitenkin Palveluntarjoajalla
                        on oikeus tähän, mikäli Palveluntarjoaja katsoo tämän tarpeellisesti.
                    </p>
                    <p>
                        Palvelussa ei saa esittää lain tai hyvän tavan vastaista materiaalia. Tällaista on muun muuassa rasistinen, pornograafinen,
                        ihmisarvoa loukaavaa, yksityisyyden suojaa loukaavaa tai uhkavaa sisältöä.
                    </p>
                    <p>
                        Käyttäjä vastaa kaikista Palvelun käytön kuluista ja siitä aiheutuvista kuluista itse.
                    </p>
                    <p>
                        Palveluntarjoaja voi pidättää Käyttäjän oikeuden käyttää Palvelua mikäli se kokee tämän parhaaksi.
                    </p>
                </div>
                <div>
                    <h5>Käyttäjätiedot</h5>
                    <p>
                        Palvelun käyttö edelyttää rekisteröitymistä. Asiakkaan tulee antaa Palveluntarjoajalle tällöin rekisteröitymistä vaativat tiedot.
                        Käyttäjä vastaa itse virheellisten tietojen korjaamisesta, kuitenkin Palveluntarjoajalla on oikeus tehdä tämän itse mikäli kokee sen
                        tarpeelliseksi.
                    </p>
                    <p>
                        Käyttäjä luo tai saa Palveluntarjoajalta Palvelun käyttöä varten salasanan ja käyttäjänimen. Näiden tietojen säilyttäminen on Käyttäjän
                        vastuulla. Palveluntarjoaja voi kuitenkin mahdollisesti auttaa hukatun salasanan tai käyttäjänimen kanssa. Mikäli kolmas osapuoli saa haltuunsa
                        luvatta Käyttäjän salasanan tai nimen, tästä tulee kertoa välittömästi Palveluntarjoajalle.
                    </p>
                    <p>
                        Palveluntarjoaja pidättää oikeuden oman harkintansa mukaan poistamaan Käyttäjän. Tästä alustavasti ilmoitetaan Käyttäjälle. Käyttäjän tiliä
                        ei saa käyttää kolmas osapuoli.
                    </p>
                </div>
                <div>
                    <h5>Immateriaalioikeudet</h5>
                    <p>
                        Palveluntarjoaja omistavat kaikki oikeudet Palveluun ja sen sisältöön, kuten esimerkiksi Palvelun kuvat, videot ja ohjelmistot, paitsi jos
                        muuten ilmaistaan.
                    </p>
                    <p>
                        Kaikkien kiinteistöjen tiedot, jotka eivät sisällä henkilötietoja, ovat Palveluntarjoajan omistamia. Luovutaessa kiinteistön tiedot
                        Palveluntarjoajalle Käyttäjä suostuu siihen, että kiinteistön tietoja käytetään Palveluntarjoajan hyödyksi ilman erillistä korvausta.
                        Käyttäjä luopuu kaikista korvausvaatimuksista, jotka koskevat Käyttäjän luovuttamia kiinteistön tietoja tai muita Käyttäjän luovuttamia
                        sisältöjä.
                    </p>
                </div>
                <div>
                    <h5>Käyttöoikeuden siirtäminen ja lakkautaminen</h5>
                    <p>
                        Käyttäjällä on oikeus siirtää Palvelun käyttöoikeutta kolmanelle osapuolelle, mikäli Palveluntarjoaja antaa luvan. Tällöin Käyttäjän tulee
                        olla yhteydessä Palveluntarjoajaan ja sopia tästä erikseen. Ilman että Palveluntarjoajalle on ilmoitettu asiasta, tälle ei ole oikeutta.
                    </p>
                    <p>
                        Käyttöoikeuden päättyessä Käyttäjän käyttäjätunnus ja salasana poistetaan.
                    </p>
                    <p>
                        Käyttäjä tiedostaa, että sopimuksen päättymisestä huolimatta kiinteistöjen tiedot, jotka eivät ole henkilötietoja jäävät mahdollisesti Palveluun, vaikka
                        tietojen syöttäjän käyttöoikeus päättyisi.
                    </p>
                </div>
                <div>
                    <h5>Käyttöehtojien muuttuminen</h5>
                    <p>
                        Palveluntarjoaja pidättää oikeuden muuttaa Käyttöehtoja. Käyttöehtojen olenaiset muutokset ilmoitetaan Käyttäjille. Mikäli Käyttäjä ei
                        hyväksy muutoksia eikä Palveluntarjoaja sovi erikseen muokatuista Käyttöehdoista, niin Käyttäjän käyttöoikeus lakkautetaan.
                    </p>
                    <p>
                        Käyttäjän katsotaan hyväksyneen muutetut ehdot, mikäli hän jatkaa Palvelun käyttämistä muutoksien voimaantulon jälkeen.
                    </p>
                </div>
                <div>
                    <h5>Muut ehdot</h5>
                    <p>
                        Palvelu saattaa sisältää linkkejä ja yhteyksiä kolmannen osapuolen sivustoihin tai muihin internet-palveluihin. Tällaisiin kolmannen
                        osapuolen tarjoamiin palveluihin sovelletaan niiden käyttöehtoja ja mahdollisesti muita sopimuksia, eikä Palveluntarjoaja vastaa niistä.
                    </p>
                </div>
                <div>
                    <h5>Sovellettava laki ja erimielisyykisen ratkaisu</h5>
                    <p>
                        Näihin Käyttöehtoihin sekä niiden tulkintaaan sovelletaan Suomen lakia.
                    </p>
                    <p>
                        Palveluntarjoajan ja Käyttän väliset erimielisyykset ratkaistaan alustavasti neuvotteluilla. Palveluntarjoajan ja Käyttäjän väliset
                        riidat ratkaistaan Suomen yleisissä tuomioistuimissa.
                    </p>
                    <p>
                        Kuluttajalla on aina oikeus saattaa asia kuluttajariitalautakunnan ratkaistavaksi ottamalla siihen yhteyttä.
                    </p>
                </div>
                <div>
                    <h4>Käyttöehtojen voimassaolo ja yhteydenotot</h4>
                    <p>
                        Nämä käyttöehdot tulevat voimaan 15.7.2022 alkaen ja ne ovat voimassa toistaiseksi.
                    </p>
                    <p>
                        Voit ottaa yhteyttä kirjallisesti tai <a href="victor.ocampo@korjausvelkalaskuri.app">sähköpostiosoite</a>.
                    </p>
                    <h6>Osoitetiedot:</h6>
                    <p>
                        Suomen Asuntosatama LKV Oy<br />
                        Mannerheimintie 94 A<br />
                        00100 Helsinki<br />
                        Suomi
                    </p>
                </div>
            </div>
        </Card>
    )
}
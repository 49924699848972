import React, { useState, useEffect, useRef } from "react";
import { heroku, domain } from "../../App";
import { FaTimesCircle } from "react-icons/fa";

const inputfieldStyle = {
  border: "1px solid rgb(204, 204, 204)",
  borderRadius: "5px",
  padding: "5px",
  outline: "none",
  maxHeight: "200px",
};

const OtherProducts = () => {
  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    company: "",
    email: "",
    phone: "",
    image: false,
    price: "",
    vat: "",
    link: "",
  });

  const [products, setProducts] = useState([]);
  const [editOtherProduct, setEditOtherProduct] = useState(undefined);
  const [selectedOtherProductData, setSelectedOtherProductData] = useState();
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    }
  };
  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  async function fetchProducts() {
    try {
      const response = await fetch(heroku + "/api/kvlstore/extraproducts/get");
      if (response.ok) {
        const data = await response.json();
        if (data != null) {
          setProducts(data);
        } else {
          setProducts([]);
        }
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error occurred while fetching products:", error);
    }
  }
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDeleteProduct = async (payload) => {
    try {
      console.log(payload);
      const response = await fetch(
        `https://korjausvelkalaskuri.app:8000/api/store/extraproducts/remove`,
        {
          method: "DELETE",
          body: JSON.stringify({
            uuid: payload,
          }),
          credentials: "include",
        }
      );

      if (response.status === 200) {
        console.log("Product deleted successfully!");
        fetchProducts();
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while deleting product:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setEditOtherProduct(undefined);
    setSelectedProductIndex(null);
    try {
      formData["uuid"] = selectedOtherProductData.uuid;
      formData["vat"] = Number(formData.vat);
      const tempPrice = Number(formData.price) * 100;

      for (let key in formData) {
        if (formData[key] === "" || formData[key] === 0) {
          formData[key] = selectedOtherProductData[key];
        }
      }

      let clone = Object.assign({}, formData)
      clone.price = tempPrice
      
      const response = await fetch(
        "https://korjausvelkalaskuri.app:8000/api/store/extraproducts/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(clone),
          credentials: "include",
        }
      );

      if (response.status === 200) {
        console.log("Product created successfully!");
        fetchProducts();
        
        // Reset form data
        setFormData({
          name: "",
          desc: "",
          company: "",
          email: "",
          phone: "",
          image: false,
          price: 0,
          vat: 0,
          link: "",
        });
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while creating product:", error);
    }
  };

  const handleSubmit = async (e, file) => {
    e.preventDefault();
    let data;
    try {
      formData.price = formData.price * 100;
      formData.vat = Number(formData.vat);
      const response = await fetch(
        "https://korjausvelkalaskuri.app:8000/api/store/extraproducts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      data = await response.json();

      if (response.status === 200) {
        console.log("Product created successfully!");
        fetchProducts();
        setFormData({
          name: "",
          desc: "",
          company: "",
          email: "",
          phone: "",
          image: false,
          price: "",
          vat: "",
          link: "",
        });
        
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while creating product:", error);
    }
    try {
      const secondFormData = new FormData();
      secondFormData.append("pic", file);
      await fetch(domain + `/api/store/extraproducts/photo/${data.uuid}`, {
        method: "POST",
        credentials: "include",
        body: secondFormData,
      });
      fetchProducts();
    } catch (error) {
      console.error("Error occurred while adding product image:", error);
    }
  };

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "80vh",
        padding: "5%",
      }}
    >
      <h1 style={{ fontSize: "28px" }}>Oheistuotteiden hallinta</h1>
      <div style={{ display: "flex", height: "100%" }}>
        {editOtherProduct ? (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleEditSubmit(e)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Tuotteen nimi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="name"
                  defaultValue={selectedOtherProductData.name}
                  key={selectedOtherProductData.name}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kuvaus</label>
                <textarea
                  name="desc"
                  style={inputfieldStyle}
                  defaultValue={selectedOtherProductData.desc}
                  key={selectedOtherProductData.desc}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Yritys</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="company"
                  defaultValue={selectedOtherProductData.company}
                  key={selectedOtherProductData.company}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Sähköposti:</label>
                <input
                  style={inputfieldStyle}
                  type="email"
                  name="email"
                  defaultValue={selectedOtherProductData.email}
                  key={selectedOtherProductData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Puhelinnumero</label>
                <input
                  style={inputfieldStyle}
                  type="tel"
                  name="phone"
                  defaultValue={selectedOtherProductData.phone}
                  key={selectedOtherProductData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Hinta</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="price"
                  defaultValue={selectedOtherProductData.price / 100}
                  key={selectedOtherProductData.price}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>ALV %</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="vat"
                  defaultValue={selectedOtherProductData.vat}
                  key={selectedOtherProductData.vat}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Link</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="link"
                  defaultValue={selectedOtherProductData.link}
                  key={selectedOtherProductData.link}
                  onChange={handleChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <button type="submit" style={{ margin: "0px" }}>
                  Muokkaa
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleSubmit(e, selectedFile)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Tuotteen nimi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kuvaus</label>
                <textarea
                  name="desc"
                  style={inputfieldStyle}
                  value={formData.desc}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Yritys</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Sähköposti:</label>
                <input
                  style={inputfieldStyle}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Puhelinnumero</label>
                <input
                  style={inputfieldStyle}
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Kuva</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <input
                      type="file"
                      accept=".png"
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      style={{ color: "transparent", width: "100px" }}
                    />
                    {selectedFile && (
                      <div>
                        {selectedFile.name}
                        <FaTimesCircle
                          onClick={handleDeleteFile}
                          style={{ paddingLeft: "5px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Hinta</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>ALV %</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Link</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                onClick={() => {
                  fetchProducts();
                }}
              >
                Luo
              </button>
            </form>
          </div>
        )}

        <div
          style={{
            width: "50%",
            height: "100%",
            overflow: "scroll",
            listStyle: "none",
            boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {products.length === 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Ei oheistuotteita
            </div>
          ) : (
            products.map((product, index) => (
              <div
                key={index}
                style={{
                  padding: "10px",
                  background: "rgb(241, 241, 241)",
                  borderBottom: "2px solid rgb(204, 204, 204)",

                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    heroku +
                    `/static/uploads/images/products/${product.uuid}.png`
                  }
                  alt="kuva"
                  style={{
                    height: "150px",
                    aspectRatio: "1/1",
                    objectFit: "contain",
                    marginRight: "30px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "4px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "20px",
                      margin: "0",
                      paddingBottom: "3px",
                    }}
                  >
                    {product.name}
                  </h2>
                  <h2 style={{ fontSize: "16px", margin: "2px" }}>
                    {product.company}
                  </h2>
                  <h2 style={{ fontSize: "16px", margin: "2px" }}>
                    {product.price / 100}€
                  </h2>
                  <text>{product.desc}</text>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    style={{
                      height: "35px",
                      fontSize: "14px",
                      borderColor:
                        selectedProductIndex === index ? "#007ab6" : "",
                    }}
                    onClick={(e) => {
                      if (editOtherProduct === e.target) {
                        setEditOtherProduct(undefined);
                      } else {
                        setEditOtherProduct(e.target);
                      }
                      setSelectedOtherProductData(product);
                      setSelectedProductIndex(
                        selectedProductIndex === index ? null : index
                      );
                      fetchProducts();
                    }}
                  >
                    Muokkaa
                  </button>
                  <button
                    style={{ height: "35px", fontSize: "14px" }}
                    onClick={() => {
                      handleDeleteProduct(product.uuid);
                      
                
                    }}
                  >
                    Poista
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherProducts;

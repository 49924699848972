//this page contains our public routes. These urls can be accessed by anyone
import { Route, Routes } from "react-router-dom";
import DefaultRoutes from "./components/routes/Routes";
import Login from "./components/login/Login";
import ProtectionPolicy from "./pages/DataProtectionPolicy";
import TermsOfService from "./pages/TermsOfService";

export const heroku = "https://kauppa.korjausvelkalaskuri.fi:8000";
export const domain = "https://korjausvelkalaskuri.app:8000";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/path/tietosuoja" element={<ProtectionPolicy />} />
        <Route path="/path/tos" element={<TermsOfService />}/>
        <Route path="*" element={<DefaultRoutes />} />
      </Routes>
    </div>
  );
}

export default App;

import { useRef, useState, useEffect } from "react";
import classes from "./Form.module.css";
import { useLocation } from "react-router-dom";
import { url } from "../../pages/HomePage";

function AddEstate(props) {
  const errorText = "Suunniteltu korjausvuosi ei voi olla menneisyydessä";
  const errorText2 =
    "Rakennettu / remontoitu viimeksi ei voi olla tulevaisuudessa";
  const errorText3 =
    "Rakennusvuosi täytyy olla nykyvuoden ja vuoden 1850 väliltä";
  const errorColor = "#fcb6b6";
  const inputfieldwith = {
    backgroundColor: "transparent",
    border: "none",
  };
  const location = useLocation();

  const [showYardCase, setShowYardCase] = useState(false);

  const [preConstructed, setPreConstructed] = useState("");
  const [preResidentialArea, setPreResidentialArea] = useState("");
  const [preCommercialArea, setPreCommercialArea] = useState("");
  const [preBathrooms, setPreBathrooms] = useState("");
  const [preFloors, setPreFloors] = useState("");

  const addressNumber = useRef();

  const [resAreaVal, setResAreaVal] = useState("");
  const [comAreaVal, setComAreaVal] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [addressValue, setAddressValue] = useState("");

  const [checkAddress, setCheckAddress] = useState("");
  const [Data, setData] = useState([]);

  //planned renovation values
  const [checkSewer, setCheckSewer] = useState("");
  const [sewerError, setSewerError] = useState(false);

  const [checkPlumbing, setCheckPlumbing] = useState("");
  const [plumbingError, setPlumbingError] = useState(false);

  const [checkBathroom, setCheckBathroom] = useState("");
  const [bathroomError, setBathroomError] = useState(false);

  const [checkHeating, setCheckHeating] = useState("");
  const [heatingError, setHeatingError] = useState(false);

  const [checkElec, setCheckElec] = useState("");
  const [elecError, setElecError] = useState(false);

  const [checkVent, setCheckVent] = useState("");
  const [ventError, setVentError] = useState(false);

  const [checkFront, setCheckFront] = useState("");
  const [frontError, setFrontError] = useState(false);

  const [checkWindows_and_balconies, setCheckWindows_and_balconies] = useState("");
  const [windows_and_balconiesError, setWindows_and_balconiesError] = useState(false);

  const [checkYard, setCheckYard] = useState("");
  const [yardError, setYardError] = useState(false);

  const [checkYardCover, setCheckYardCover] = useState("");
  const [yardCoverError, setYardCoverError] = useState(false);

  const [checkRoof, setCheckRoof] = useState("");
  const [roofError, setRoofError] = useState(false);

  const [checkElevator, setCheckElevator] = useState("");
  const [elevatorError, setElevatorError] = useState(false);

  //renovated values
  const [sewerRenovated, setSewerRenovated] = useState("");
  const [sewerRenovatedError, setSewerRenovatedError] = useState(false);

  const [plumbingRenovated, setPlumbingRenovated] = useState("");
  const [plumbingRenovatedError, setPlumbingRenovatedError] = useState(false);

  const [bathroomRenovated, setBathroomRenovated] = useState("");
  const [bathroomRenovatedError, setBathroomRenovatedError] = useState(false);

  const [heatingRenovated, setHeatingRenovated] = useState("");
  const [heatingRenovatedError, setHeatingRenovatedError] = useState(false);

  const [elecRenovated, setElecRenovated] = useState("");
  const [elecRenovatedError, setElecRenovatedError] = useState(false);

  const [ventRenovated, setVentRenovated] = useState("");
  const [ventRenovatedError, setVentRenovatedError] = useState(false);

  const [frontRenovated, setFrontRenovated] = useState("");
  const [frontRenovatedError, setFrontRenovatedError] = useState(false);

  const [windows_and_balconiesRenovated, setWindows_and_balconiesRenovated] = useState("");
  const [windows_and_balconiesRenovatedError, setWindows_and_balconiesRenovatedError] = useState(false);

  const [yardRenovated, setYardRenovated] = useState("");
  const [yardRenovatedError, setYardRenovatedError] = useState(false);

  const [yardCoverRenovated, setYardCoverRenovated] = useState("");
  const [yardCoverRenovatedError, setYardCoverRenovatedError] = useState(false);

  const [roofRenovated, setRoofRenovated] = useState("");
  const [roofRenovatedError, setRoofRenovatedError] = useState(false);

  const [elevatorRenovated, setElevatorRenovated] = useState("");
  const [elevatorRenovatedError, setElevatorRenovatedError] = useState(false);

  //constructed value
  const [constructed, setConstructed] = useState("");
  const [constructedError, setConstructedError] = useState(false);

  useEffect(() => {
    fetch(url + "api/properties", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      });
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfAddressExist();
    }, 2000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAddress]);

  //planned renovation timeouts
  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfSewerExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSewer]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfPlumbingExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPlumbing]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfBathroomExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBathroom]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfHeatingExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkHeating]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElecExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkElec]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfVentExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkVent]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfFrontExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFront]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfWindows_and_balconiesExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkWindows_and_balconies]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkYard]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardCoverExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkYardCover]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfRoofExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkRoof]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElevatorExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkElevator]);

  //renovated timeouts
  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfSewerExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sewerRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfPlumbingExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plumbingRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfBathroomExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bathroomRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfHeatingExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatingRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElecExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elecRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfVentExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfFrontExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfWindows_and_balconiesExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windows_and_balconiesRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardCoverExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardCoverRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfRoofExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roofRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElevatorExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elevatorRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfConstructedExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constructed]);

  function onlyAcceptLetters(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;

    // Block double spaces and spaces at the beginning
    if (
      inputKey === " " &&
      (inputValue.endsWith(" ") || selectionStart === 0)
    ) {
      event.preventDefault();
    }

    // Allow the delete button, arrow keys, and backspace
    if (
      inputKey === "Delete" ||
      inputKey.includes("Arrow") ||
      inputKey === "Backspace"
    ) {
      return;
    }

    // Block any characters that are not letters (uppercase or lowercase)
    if (!/^[a-zA-ZäöÄÖ]$/.test(inputKey)) {
      event.preventDefault();
    }
  }

  function onlyAcceptNumbers(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;

    // Block double spaces and spaces at the beginning
    if (
      inputKey === " " &&
      (inputValue.endsWith(" ") || selectionStart === 0)
    ) {
      event.preventDefault();
    }

    // Allow the delete button and arrow keys
    if (inputKey === "Delete" || inputKey.includes("Arrow")) {
      return;
    }

    // Block any additional "." or "," at the start of the input and only allow numbers (0-9) and the backspace key
    if (
      inputKey === "." ||
      inputKey === "," ||
      (!/[\d]/.test(inputKey) && inputKey !== "Backspace")
    ) {
      event.preventDefault();
    }
  }

  function onlyAcceptNumericValues(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;
    const selectionEnd = inputElement.selectionEnd;

    if (inputKey === "," || inputKey === ".") {
      // Replace "," or "." with "." and prevent multiple periods
      if (!inputValue.includes(".") && inputValue !== "") {
        inputElement.value =
          inputValue.slice(0, selectionStart) +
          "." +
          inputValue.slice(selectionEnd);
        inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
        event.preventDefault();
      }
    } else if (inputKey === " ") {
      // Block double spaces and spaces at the beginning
      if (inputValue.endsWith(" ") || selectionStart === 0) {
        event.preventDefault();
      }
    } else if (inputKey === "Delete" || inputKey.includes("Arrow")) {
      // Allow the delete button and arrow keys
      return;
    }

    // Block any additional "." or "," at the start of the input and only allow numbers (0-9) and the backspace key
    if (
      inputKey === "." ||
      inputKey === "," ||
      (!/[\d. ]/.test(inputKey) && inputKey !== "Backspace") ||
      (inputKey === " " && inputValue === "")
    ) {
      event.preventDefault();
    }
  }

  function sanitizeNumberString(value) {
    var newValue = value + "";
    return Number(newValue.replace(/[.,](?![\d])/g, ""));
  }

  function isNotCorrect(i) {
    if (isNaN(i) || i === Infinity) {
      return 0;
    } else {
      return Math.round(
        ((preResidentialArea + preCommercialArea) / preFloors) * 1.2
      );
    }
  }

  function isNotCorrect2(i) {
    if (isNaN(i) || i === Infinity) {
      return 0;
    } else {
      return (
        Math.round(
          ((preResidentialArea + preCommercialArea) / preFloors) * 1.2
        ) * props.defaults[16]
      );
    }
  }

  const preFilledData = {
    viemäriputket1: 40,
    viemäriputket2: 2022,
    viemäriputket3: 50,
    viemäriputket4: 0,
    viemäriputket5: 0,
    viemäriputket6: 0,
    käyttövesiputket1: 40,
    käyttövesiputket2: 2022,
    käyttövesiputket3: 50,
    käyttövesiputket4: 0,
    käyttövesiputket5: 0,
    käyttövesiputket6: 0,
    märkätilat1: 1,
    märkätilat2: 2022,
    märkätilat3: 50,
    märkätilat4: 0,
    märkätilat5: 0,
    märkätilat6: 0,
    lämmitysputkisto1: 40,
    lämmitysputkisto2: 2022,
    lämmitysputkisto3: 50,
    lämmitysputkisto4: 0,
    lämmitysputkisto5: 0,
    lämmitysputkisto6: 0,
    sähkö1: 40,
    sähkö2: 2022,
    sähkö3: 80,
    sähkö4: 0,
    sähkö5: 0,
    sähkö6: 0,
    ilmanvaihto1: 40,
    ilmanvaihto2: 2022,
    ilmanvaihto3: 80,
    ilmanvaihto4: 0,
    ilmanvaihto5: 0,
    ilmanvaihto6: 0,
    julkisivut1: 40,
    julkisivut2: 2022,
    julkisivut3: 50,
    julkisivut4: 0,
    julkisivut5: 0,
    julkisivut6: 0,
    salaojat1: 0,
    salaojat2: 2022,
    salaojat3: 35,
    salaojat4: 0,
    salaojat5: 0,
    salaojat6: 0,
    katto1: 0,
    katto2: 2022,
    katto3: 30,
    katto4: 0,
    katto5: 0,
    katto6: 0,
    hissi1: 0,
    hissi2: 2022,
    hissi3: 30,
    hissi4: 0,
    hissi5: 0,
    hissi6: 0,
  };

  const nameInputRef = useRef();
  const addressInputRef = useRef();
  const postNumberInputRef = useRef();
  const cityInputRef = useRef();
  /* const moreInfoInputRef = useRef(); */
  //estaten tiedot
  const propertyTypeInputRef = useRef();
  const constructedInputRef = useRef();
  const floorsInputRef = useRef();
  const apartmentsInputRef = useRef();
  const residentialAreaInputRef = useRef();
  const commercialAreaInputRef = useRef();
  const apartmentAreaInputRef = useRef();
  /* const loanPeriodInputRef = useRef(); */
  const rateInputRef = useRef();
  //LVIS korjaustiedot
  const sewer_areaInputRef = useRef();
  const sewer_renovatedInputRef = useRef();
  const sewer_plannedRenovationInputRef = useRef();
  const sewer_rPriceInputRef = useRef();
  //Käyttövesiputket
  const plumbing_areaInputRef = useRef();
  const plumbing_renovatedInputRef = useRef();
  const plumbing_plannedRenovationInputRef = useRef();
  const plumbing_rPriceInputRef = useRef();
  //Märkätilat
  const bathroomsInputRef = useRef();
  const bathroom_renovatedInputRef = useRef();
  const bathroom_plannedRenovationInputRef = useRef();
  const bathroom_priceInputRef = useRef();
  //lämmitysputkisto ja -varaaja
  const heating_areaInputRef = useRef();
  const heating_renovatedInputRef = useRef();
  const heating_plannedRenovationInputRef = useRef();
  const heating_rPriceInputRef = useRef();
  //sähkö- ja telejärjestelmä
  const elec_areaInputRef = useRef();
  const elec_renovatedInputRef = useRef();
  const elec_plannedRenovationInputRef = useRef();
  const elec_rPriceInputRef = useRef();
  //Ilmanvaihto, hormit
  const vent_areaInputRef = useRef();
  const vent_renovatedInputRef = useRef();
  const vent_plannedRenovationInputRef = useRef();
  const vent_rPriceInputRef = useRef();
  //Muut korjaustiedot, eli julkisivut
  const front_areaInputRef = useRef();
  const front_renovatedInputRef = useRef();
  const front_plannedRenovationInputRef = useRef();
  const front_rPriceInputRef = useRef();
  //Muut korjaustiedot, eli ikkunat ja parvekkeet
  const windows_and_balconies_areaInputRef = useRef();
  const windows_and_balconies_renovatedInputRef = useRef();
  const windows_and_balconies_plannedRenovationInputRef = useRef();
  const windows_and_balconies_rPriceInputRef = useRef();
  //Salaoja ja sokkeli
  const yard_areaInputRef = useRef();
  const yard_renovatedInputRef = useRef();
  const yard_plannedRenovationInputRef = useRef();
  const yard_priceInputRef = useRef();
  //Pihakansi
  const yard_cover_areaInputRef = useRef();
  const yard_cover_renovatedInputRef = useRef();
  const yard_cover_plannedRenovationInputRef = useRef();
  const yard_cover_priceInputRef = useRef();
  //Katto
  const roof_areaInputRef = useRef();
  const roof_renovatedInputRef = useRef();
  const roof_plannedRenovationInputRef = useRef();
  const roof_priceInputRef = useRef();
  //Hissi
  const elevatorsInputRef = useRef();
  const elevators_renovatedInputRef = useRef();
  const elevators_plannedRenovationInputRef = useRef();
  const elevators_priceInputRef = useRef();

  function convertNumber(property) {
    var old = property + "";
    var current = old.split(",").join(".");
    return current === "" ? 0 : current;
  }

  function submitHandler(event) {
    event.preventDefault();
    document.getElementById("nappi").disabled = true;
    setTimeout(() => {
      document.getElementById("nappi").disabled = false;
    }, 1000);

    const enteredname = nameInputRef.current.value;
    const enteredaddress = `${address1}${
      address2 !== "" ? " / " : ""
    }${address2}${address3 !== "" ? " / " : ""}${address3}`;
    const enteredpostNumber = postNumberInputRef.current.value;
    const enteredcity = cityInputRef.current.value;
    /* const enteredmoreInfo = moreInfoInputRef.current.value; */
    //estaten tiedot
    const enteredpropertyType = propertyTypeInputRef.current.value;
    const enteredconstructed = constructedInputRef.current.value;
    const enteredfloors = floorsInputRef.current.value;
    const enteredapartments = apartmentsInputRef.current.value;
    const enteredresidentialArea = residentialAreaInputRef.current.value;
    const enteredcommercialArea = commercialAreaInputRef.current.value;
    const enteredapartmentArea = apartmentAreaInputRef.current.value;
    /* const enteredloanPeriod = loanPeriodInputRef.current.value; */
    const enteredrate = sanitizeNumberString(rateInputRef.current.value);
    //LVIS korjaustiedot
    const enteredsewer_area = sewer_areaInputRef.current.value;
    const enteredsewer_renovated = sewer_renovatedInputRef.current.value;
    const enteredsewer_plannedRenovation =
      sewer_plannedRenovationInputRef.current.value;
    const enteredsewer_rPrice = sewer_rPriceInputRef.current.value;
    //Käyttövesiputket
    const enteredplumbing_area = plumbing_areaInputRef.current.value;
    const enteredplumbing_renovated = plumbing_renovatedInputRef.current.value;
    const enteredplumbing_plannedRenovation =
      plumbing_plannedRenovationInputRef.current.value;
    const enteredplumbing_rPrice = plumbing_rPriceInputRef.current.value;
    //Märkätilat
    const enteredbathrooms = bathroomsInputRef.current.value;
    const enteredbathroom_renovated = bathroom_renovatedInputRef.current.value;
    const enteredbathroom_plannedRenovation =
      bathroom_plannedRenovationInputRef.current.value;
    const enteredbathroom_price = bathroom_priceInputRef.current.value;
    //lämmitysputkisto ja -varaaja
    const enteredheating_area = heating_areaInputRef.current.value;
    const enteredheating_renovated = heating_renovatedInputRef.current.value;
    const enteredheating_plannedRenovation =
      heating_plannedRenovationInputRef.current.value;
    const enteredheating_rPrice = heating_rPriceInputRef.current.value;
    //sähkö- ja telejärjestelmä
    const enteredelec_area = elec_areaInputRef.current.value;
    const enteredelec_renovated = elec_renovatedInputRef.current.value;
    const enteredelec_plannedRenovation =
      elec_plannedRenovationInputRef.current.value;
    const enteredelec_rPrice = elec_rPriceInputRef.current.value;
    //Ilmanvaihto, hormit
    const enteredvent_area = vent_areaInputRef.current.value;
    const enteredvent_renovated = vent_renovatedInputRef.current.value;
    const enteredvent_plannedRenovation =
      vent_plannedRenovationInputRef.current.value;
    const enteredvent_rPrice = vent_rPriceInputRef.current.value;
    //Muut korjaustiedot Julkisivut
    const enteredfront_area = front_areaInputRef.current.value;
    const enteredfront_renovated = front_renovatedInputRef.current.value;
    const enteredfront_plannedRenovation =
      front_plannedRenovationInputRef.current.value;
    const enteredfront_rPrice = front_rPriceInputRef.current.value;
    //Muut korjaustiedot Ikkunat ja Parvekkeet
    const enteredwindows_and_balconies_area = windows_and_balconies_areaInputRef.current.value;
    const enteredwindows_and_balconies_renovated = windows_and_balconies_renovatedInputRef.current.value;
    const enteredwindows_and_balconies_plannedRenovation =
      windows_and_balconies_plannedRenovationInputRef.current.value;
    const enteredwindows_and_balconies_rPrice = windows_and_balconies_rPriceInputRef.current.value;
    //Salaojat ja sokkelli
    const enteredyard_area = yard_areaInputRef.current.value;
    const enteredyard_renovated = yard_renovatedInputRef.current.value;
    const enteredyard_plannedRenovation =
      yard_plannedRenovationInputRef.current.value;
    const enteredyard_price = yard_priceInputRef.current.value;

    //Pihakansi
    const enteredyard_cover_area = showYardCase
      ? yard_cover_areaInputRef.current.value
      : 0;
    const enteredyard_cover_renovated = showYardCase
      ? yard_cover_renovatedInputRef.current.value
      : 0;
    const enteredyard_cover_plannedRenovation = showYardCase
      ? yard_cover_plannedRenovationInputRef.current.value
      : 0;
    const enteredyard_cover_price = showYardCase
      ? yard_cover_priceInputRef.current.value
      : 0;

    //Katto
    const enteredroof_area = roof_areaInputRef.current.value;
    const enteredroof_renovated = roof_renovatedInputRef.current.value;
    const enteredroof_plannedRenovation =
      roof_plannedRenovationInputRef.current.value;
    const enteredroof_price = roof_priceInputRef.current.value;
    //Hissi
    const enteredelevators = elevatorsInputRef.current.value;
    const enteredelevators_renovated =
      elevators_renovatedInputRef.current.value;
    const enteredelevators_plannedRenovation =
      elevators_plannedRenovationInputRef.current.value;
    const enteredelevators_price = elevators_priceInputRef.current.value;

    const estateData = {
      name: enteredname,
      address: enteredaddress,
      postNumber: enteredpostNumber,
      city: enteredcity,
      /* moreInfo: enteredmoreInfo, */
      //estaten tiedot
      propertyType: enteredpropertyType,
      constructed: enteredconstructed,
      floors: enteredfloors,
      apartments: enteredapartments,
      residentialArea: enteredresidentialArea,
      commercialArea: enteredcommercialArea,
      apartmentArea: enteredapartmentArea,
      loanPeriod: 20 /* enteredloanPeriod */,
      rate: enteredrate === 0 ? 0.1 : enteredrate,
      //LVIS korjaustiedot
      sewer_area: enteredsewer_area,
      sewer_renovated: enteredsewer_renovated,
      sewer_plannedRenovation: enteredsewer_plannedRenovation,
      sewer_rPrice: enteredsewer_rPrice,
      //Käyttövesiputket
      plumbing_area: enteredplumbing_area,
      plumbing_renovated: enteredplumbing_renovated,
      plumbing_plannedRenovation: enteredplumbing_plannedRenovation,
      plumbing_rPrice: enteredplumbing_rPrice,
      //Märkätilat
      bathrooms: enteredbathrooms,
      bathroom_renovated: enteredbathroom_renovated,
      bathroom_plannedRenovation: enteredbathroom_plannedRenovation,
      bathroom_price: enteredbathroom_price,
      //lämmitysputkisto ja -varaaja
      heating_area: enteredheating_area,
      heating_renovated: enteredheating_renovated,
      heating_plannedRenovation: enteredheating_plannedRenovation,
      heating_rPrice: enteredheating_rPrice,
      //sähkö- ja telejärjestelmä
      elec_area: enteredelec_area,
      elec_renovated: enteredelec_renovated,
      elec_plannedRenovation: enteredelec_plannedRenovation,
      elec_rPrice: enteredelec_rPrice,
      //Ilmanvaihto, hormit
      vent_area: enteredvent_area,
      vent_renovated: enteredvent_renovated,
      vent_plannedRenovation: enteredvent_plannedRenovation,
      vent_rPrice: enteredvent_rPrice,
      //Muut korjaustiedot julkisivu
      front_area: enteredfront_area,
      front_renovated: enteredfront_renovated,
      front_plannedRenovation: enteredfront_plannedRenovation,
      front_rPrice: enteredfront_rPrice,
      //Muut korjaustiedot ikkunat
      windows_and_balconies_area: enteredwindows_and_balconies_area,
      windows_and_balconies_renovated: enteredwindows_and_balconies_renovated,
      windows_and_balconies_plannedRenovation: enteredwindows_and_balconies_plannedRenovation,
      windows_and_balconies_rPrice: enteredwindows_and_balconies_rPrice,
      //Salaojat ja sokkeli
      yard_area: enteredyard_area,
      yard_renovated: enteredyard_renovated,
      yard_plannedRenovation: enteredyard_plannedRenovation,
      yard_price: enteredyard_price,
      //Pihakansi
      yard_cover_area: enteredyard_cover_area,
      yard_cover_renovated: enteredyard_cover_renovated,
      yard_cover_plannedRenovation: enteredyard_cover_plannedRenovation,
      yard_cover_price: enteredyard_cover_price,
      //Katto
      roof_area: enteredroof_area,
      roof_renovated: enteredroof_renovated,
      roof_plannedRenovation: enteredroof_plannedRenovation,
      roof_price: enteredroof_price,
      //Hissi
      elevators: enteredelevators,
      elevators_renovated: enteredelevators_renovated,
      elevators_plannedRenovation: enteredelevators_plannedRenovation,
      elevators_price: enteredelevators_price,
    };
    if (
      sewerError ||
      sewerRenovatedError ||
      plumbingError ||
      plumbingRenovatedError ||
      bathroomError ||
      bathroomRenovatedError ||
      heatingError ||
      heatingRenovatedError ||
      elecError ||
      elecRenovatedError ||
      ventError ||
      ventRenovatedError ||
      frontError ||
      frontRenovatedError ||
      windows_and_balconiesError ||
      windows_and_balconiesRenovatedError ||
      yardError ||
      yardRenovatedError ||
      roofError ||
      roofRenovatedError ||
      elevatorError ||
      elevatorRenovatedError ||
      constructedError ||
      yardCoverError === true
    ) {
    } else {
      props.onAddEstate(estateData);
    }
  }

  /*  console.log("calc:", (preResidentialArea + preCommercialArea) / preFloors * 1.2); */
  function setAddress(i, value) {
    if (Number(i) === 1) {
      setAddress1(value);
    } else if (Number(i) === 2) {
      setAddress2(value);
    } else if (Number(i) === 3) {
      setAddress3(value);
    }
  }

  function returnAddress(index) {
    if (Number(index) === 1) {
      return address1;
    } else if (Number(index) === 2) {
      return address2;
    } else if (Number(index) === 3) {
      return address3;
    }
  }
  function setRef() {
    addressInputRef.current.value = "";
  }

  function avoidSpaces(name, i) {
    if (name === "Huoneistojenala") {
      var key = i + "";
      setResAreaVal(key.replace(" ", ""));
    } else if (name === "commercialArea") {
      key = i + "";
      setComAreaVal(key.replace(" ", ""));
    }
  }

  function checkIfAddressExist() {
    //creates group that contains all address variabless that have 2 or more addresses
    var addressGroup = [];
    for (var z = 0; z < Data.length; z++) {
      var addr = Data[z].address + "";
      var addrId = Data[z].id + "";
      var addrCity = Data[z].city + "";
      if (addr.includes(" / ")) {
        var addressess2 = addr.split(" / ");
        console.log(addressess2);
        for (var j = 0; j < addressess2.length; j++) {
          var newAddress3 = addressess2[j] + "";
          addressGroup.push({
            address: newAddress3.toLowerCase(),
            city: addrCity,
            id: addrId,
          });
        }
      } else {
        addressGroup.push({
          address: addr.toLowerCase(),
          city: addrCity,
          id: addrId,
        });
      }
    }
    console.log(addressGroup);
    //makes all multi address variables to own addressess and checks if your search is on
    for (var i = 0; i < addressGroup.length; i++) {
      var newAddress = addressGroup[i].address + "";
      var newId = addressGroup[i].id + "";
      var newCity = addressGroup[i].city + "";
      if (checkAddress.toLowerCase() === newAddress.toLowerCase()) {
        if (
          window.confirm(
            `Osoite ${newAddress} on jo käytössä paikkakunnalla ${newCity}. Haluatko siirtyä kiinteistöön?`
          )
        ) {
          window.location.href = `/kiinteisto/${newId}`;
        }
      }
    }
  }
  //check if exist functions
  function checkIfSewerExists() {
    if (checkSewer < new Date().getFullYear() && checkSewer !== "") {
      setSewerError(true);
    } else {
      setSewerError(false);
    }
  }
  function checkIfSewerExists2() {
    if (sewerRenovated > new Date().getFullYear() && sewerRenovated !== "") {
      setSewerRenovatedError(true);
    } else {
      setSewerRenovatedError(false);
    }
  }

  function checkIfPlumbingExists() {
    if (checkPlumbing < new Date().getFullYear() && checkPlumbing !== "") {
      setPlumbingError(true);
    } else {
      setPlumbingError(false);
    }
  }
  function checkIfPlumbingExists2() {
    if (
      plumbingRenovated > new Date().getFullYear() &&
      plumbingRenovated !== ""
    ) {
      setPlumbingRenovatedError(true);
    } else {
      setPlumbingRenovatedError(false);
    }
  }

  function checkIfBathroomExists() {
    if (checkBathroom < new Date().getFullYear() && checkBathroom !== "") {
      setBathroomError(true);
    } else {
      setBathroomError(false);
    }
  }
  function checkIfBathroomExists2() {
    if (
      bathroomRenovated > new Date().getFullYear() &&
      bathroomRenovated !== ""
    ) {
      setBathroomRenovatedError(true);
    } else {
      setBathroomRenovatedError(false);
    }
  }

  function checkIfHeatingExists() {
    if (checkHeating < new Date().getFullYear() && checkHeating !== "") {
      setHeatingError(true);
    } else {
      setHeatingError(false);
    }
  }
  function checkIfHeatingExists2() {
    if (
      heatingRenovated > new Date().getFullYear() &&
      heatingRenovated !== ""
    ) {
      setHeatingRenovatedError(true);
    } else {
      setHeatingRenovatedError(false);
    }
  }

  function checkIfElecExists() {
    if (checkElec < new Date().getFullYear() && checkElec !== "") {
      setElecError(true);
    } else {
      setElecError(false);
    }
  }
  function checkIfElecExists2() {
    if (elecRenovated > new Date().getFullYear() && elecRenovated !== "") {
      setElecRenovatedError(true);
    } else {
      setElecRenovatedError(false);
    }
  }

  function checkIfVentExists() {
    if (checkVent < new Date().getFullYear() && checkVent !== "") {
      setVentError(true);
    } else {
      setVentError(false);
    }
  }
  function checkIfVentExists2() {
    if (ventRenovated > new Date().getFullYear() && ventRenovated !== "") {
      setVentRenovatedError(true);
    } else {
      setVentRenovatedError(false);
    }
  }

  function checkIfFrontExists() {
    if (checkFront < new Date().getFullYear() && checkFront !== "") {
      setFrontError(true);
    } else {
      setFrontError(false);
    }
  }
  function checkIfFrontExists2() {
    if (frontRenovated > new Date().getFullYear() && frontRenovated !== "") {
      setFrontRenovatedError(true);
    } else {
      setFrontRenovatedError(false);
    }
  }

  function checkIfWindows_and_balconiesExists() {
    if (checkWindows_and_balconies < new Date().getFullYear() && checkWindows_and_balconies !== "") {
      setWindows_and_balconiesError(true);
    } else {
      setWindows_and_balconiesError(false);
    }
  }
  function checkIfWindows_and_balconiesExists2() {
    if (windows_and_balconiesRenovated > new Date().getFullYear() && windows_and_balconiesRenovated !== "") {
      setWindows_and_balconiesRenovatedError(true);
    } else {
      setWindows_and_balconiesRenovatedError(false);
    }
  }

  function checkIfYardExists() {
    if (checkYard < new Date().getFullYear() && checkYard !== "") {
      setYardError(true);
    } else {
      setYardError(false);
    }
  }
  function checkIfYardExists2() {
    if (yardRenovated > new Date().getFullYear() && yardRenovated !== "") {
      setYardRenovatedError(true);
    } else {
      setYardRenovatedError(false);
    }
  }

  function checkIfYardCoverExists() {
    if (checkYardCover < new Date().getFullYear() && checkYardCover !== "") {
      setYardCoverError(true);
    } else {
      setYardCoverError(false);
    }
  }
  function checkIfYardCoverExists2() {
    if (
      yardCoverRenovated > new Date().getFullYear() &&
      yardCoverRenovated !== ""
    ) {
      setYardCoverRenovatedError(true);
    } else {
      setYardCoverRenovatedError(false);
    }
  }

  function checkIfRoofExists() {
    if (checkRoof < new Date().getFullYear() && checkRoof !== "") {
      setRoofError(true);
    } else {
      setRoofError(false);
    }
  }
  function checkIfRoofExists2() {
    if (roofRenovated > new Date().getFullYear() && roofRenovated !== "") {
      setRoofRenovatedError(true);
    } else {
      setRoofRenovatedError(false);
    }
  }

  function checkIfElevatorExists() {
    if (checkElevator < new Date().getFullYear() && checkElevator !== "") {
      setElevatorError(true);
    } else {
      setElevatorError(false);
    }
  }
  function checkIfElevatorExists2() {
    if (
      elevatorRenovated > new Date().getFullYear() &&
      elevatorRenovated !== ""
    ) {
      setElevatorRenovatedError(true);
    } else {
      setElevatorRenovatedError(false);
    }
  }
  function checkIfConstructedExists() {
    if (
      (constructed < 1850 || constructed > new Date().getFullYear()) &&
      constructed !== ""
    ) {
      setConstructedError(true);
    } else {
      setConstructedError(false);
    }
  }

  return (
    <div style={{ marginTop: "5%" }}>
      <form className={classes.form} onSubmit={submitHandler}>
        <table className={classes.local}>
          <thead>
            <tr>
              <th
                onClick={() =>
                  console.log(
                    showYardCase ? preResidentialArea + preCommercialArea : 0
                  )
                }
              >
                Taloyhtiön nimi
              </th>
              <th>
                <section
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <>Osoite</>
                  <select
                    ref={addressNumber}
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      width: "20%",
                    }}
                    onChange={() => {
                      setRef();
                      setAddressValue(
                        returnAddress(addressNumber.current.value)
                      );
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </section>
              </th>
              <th>Postinumero</th>
              <th>Kaupunki</th>
              <th>Kiinteistön tyyppi</th>
              <th /* colSpan={2} */>Rakennusvuosi</th>
            </tr>
            <tr>
              <td>
                <input
                  style={inputfieldwith}
                  defaultValue={new URLSearchParams(location.search).get(
                    "name"
                  )}
                  type="text"
                  autoComplete="off"
                  required
                  id="name"
                  ref={nameInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  onChange={(event) => {
                    setAddress(
                      addressNumber.current.value,
                      addressInputRef.current.value
                    );
                    setCheckAddress(event.target.value);
                  }}
                  autoComplete="off"
                  defaultValue={addressValue}
                  key={addressValue}
                  id="address"
                  ref={addressInputRef}
                />
              </td>

              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  required
                  id="postNumber"
                  ref={postNumberInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptLetters(e)}
                  required
                  id="city"
                  ref={cityInputRef}
                />
              </td>
              <td>
                <select
                  style={{ border: "none", backgroundColor: "transparent" }}
                  type="number"
                  autoComplete="off"
                  required
                  id="propertyType"
                  ref={propertyTypeInputRef}
                >
                  <option value="0">Kerrostalo</option>
                  <option value="1" disabled>Pientalo</option>
                </select>
              </td>
              <td
                style={{ backgroundColor: constructedError ? errorColor : "" }}
              >
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  required
                  onChange={(e) => {
                    setPreConstructed(sanitizeNumberString(e.target.value));
                    setConstructed(sanitizeNumberString(e.target.value));
                  }}
                  id="constructed"
                  title={constructedError ? errorText3 : ""}
                  ref={constructedInputRef}
                />
              </td>
            </tr>
            <tr>
              <th>Kerrosmäärä</th>
              <th>Huoneistojen määrä</th>
              <th>
                Huoneistoala m<sup>2</sup>
              </th>
              <th>
                Liikehuoneistojen pinta-ala m<sup>2</sup>
              </th>
              <th>Tarkasteltavan asunnon pinta-ala</th>
              {/* <th>Laina-aika</th> */}
              <th>Korko</th>
            </tr>
            <tr>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  required
                  onChange={(e) => {
                    setPreFloors(
                      sanitizeNumberString(
                        Number(convertNumber(e.target.value))
                      )
                    );
                  }}
                  id="floors"
                  ref={floorsInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="number"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  required
                  onChange={(e) => setPreBathrooms(e.target.value)}
                  id="apartments"
                  ref={apartmentsInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  value={resAreaVal}
                  required
                  onChange={(e) => {
                    setPreResidentialArea(
                      sanitizeNumberString(
                        Number(convertNumber(e.target.value))
                      )
                    );
                    avoidSpaces("Huoneistojenala", e.target.value);
                  }}
                  id="Huoneistojenala"
                  ref={residentialAreaInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  value={comAreaVal}
                  required
                  onChange={(e) => {
                    setPreCommercialArea(
                      sanitizeNumberString(
                        Number(convertNumber(e.target.value))
                      )
                    );
                    avoidSpaces("commercialArea", e.target.value);
                  }}
                  id="commercialArea"
                  ref={commercialAreaInputRef}
                />
              </td>
              <td>
                <input
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="apartmentArea"
                  ref={apartmentAreaInputRef}
                />
              </td>
              {/* <td><div style={{ display: "flex", border: "none" }}><input
              style={inputfieldwith}
              type="text"
              autoComplete="off"
              defaultValue={20}
              id="loanPeriod"
              ref={loanPeriodInputRef}
            /><span>v</span></div></td> */}
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={4.5}
                    id="rate"
                    ref={rateInputRef}
                  />
                  <span>%</span>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Remontoitava määrä</th>
              <th>Rakennettu / remontoitu viimeksi v</th>
              <th>Kestoikä</th>
              <th>Suunniteltu korjausvuosi v</th>
              <th colSpan={2}>Hinta € / korjattava{/*  m<sup>2</sup> */}</th>
            </tr>
          </thead>
          <tbody>
            <th>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  justifyContent: "space-between",
                }}
              >
                <>Viemäriputket</>
              </div>
            </th>
            <td>
              <input
                defaultValue={
                  /* preFilledData.viemäriputket1 */ preResidentialArea +
                  preCommercialArea
                }
                style={inputfieldwith}
                type="text"
                autoComplete="off"
                onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                required
                id="sewer_area"
                ref={sewer_areaInputRef}
              />
            </td>
            <td
              style={{ backgroundColor: sewerRenovatedError ? errorColor : "" }}
            >
              <input
                defaultValue={/* preFilledData.viemäriputket2 */ preConstructed}
                style={inputfieldwith}
                type="text"
                autoComplete="off"
                onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                onChange={(e) => {
                  setSewerRenovated(e.target.value);
                }}
                required
                id="sewer_renovated"
                title={sewerRenovatedError ? errorText2 : ""}
                ref={sewer_renovatedInputRef}
              />
            </td>
            <td>
              <div>
                <span>{props.defaults[7]}v</span>
              </div>
            </td>
            <td style={{ backgroundColor: sewerError ? errorColor : "" }}>
              <input
                placeholder="tuo PTS tai anna olettaa"
                style={inputfieldwith}
                type="text"
                autoComplete="off"
                onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                onChange={(e) => {
                  setCheckSewer(e.target.value);
                }}
                id="sewer_plannedRenovation"
                title={sewerError ? errorText : ""}
                ref={sewer_plannedRenovationInputRef}
              />
            </td>
            <td colSpan={2}>
              <div style={{ display: "flex", border: "none" }}>
                <input
                  defaultValue={props.defaults[17]}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="sewer_rPrice"
                  ref={sewer_rPriceInputRef}
                />
                <span>
                  m<sup>2</sup>
                </span>
              </div>
            </td>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Käyttövesiputket</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.käyttövesiputket1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="plumbing_area"
                  ref={plumbing_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: plumbingRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={
                    /* preFilledData.käyttövesiputket2 */ preConstructed
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setPlumbingRenovated(e.target.value);
                  }}
                  required
                  id="plumbing_renovated"
                  title={plumbingRenovatedError ? errorText2 : ""}
                  ref={plumbing_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[5]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: plumbingError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckPlumbing(e.target.value);
                  }}
                  id="plumbing_plannedRenovation"
                  title={plumbingError ? errorText : ""}
                  ref={plumbing_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[15]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="plumbing_rPrice"
                    ref={plumbing_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Märkätilat</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={/* preFilledData.märkätilat1 */ preBathrooms}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="bathrooms"
                  ref={bathroomsInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: bathroomRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.märkätilat2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setBathroomRenovated(e.target.value);
                  }}
                  required
                  id="bathroom_renovated"
                  title={bathroomRenovatedError ? errorText2 : ""}
                  ref={bathroom_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[0]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: bathroomError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckBathroom(e.target.value);
                  }}
                  id="bathroom_plannedRenovation"
                  title={bathroomError ? errorText : ""}
                  ref={bathroom_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[10]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="bathroom_price"
                    ref={bathroom_priceInputRef}
                  />
                  <span>kpl</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Lämmitysputkisto</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.lämmitysputkisto1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="heating_area"
                  ref={heating_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: heatingRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={
                    /* preFilledData.lämmitysputkisto2 */ preConstructed
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setHeatingRenovated(e.target.value);
                  }}
                  required
                  id="heating_renovated"
                  title={heatingRenovatedError ? errorText2 : ""}
                  ref={heating_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[4]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: heatingError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckHeating(e.target.value);
                  }}
                  id="heating_plannedRenovation"
                  title={heatingError ? errorText : ""}
                  ref={heating_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[14]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="heating_rPrice"
                    ref={heating_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Sähkö- ja telejärjestelmä</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.sähkö1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="elec_area"
                  ref={elec_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: elecRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.sähkö2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setElecRenovated(e.target.value);
                  }}
                  required
                  id="elec_renovated"
                  title={elecRenovatedError ? errorText2 : ""}
                  ref={elec_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[1]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: elecError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckElec(e.target.value);
                  }}
                  id="elec_plannedRenovation"
                  title={elecError ? errorText : ""}
                  ref={elec_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[11]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="elec_rPrice"
                    ref={elec_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Ilmanvaihto, hormit</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.ilmanvaihto1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="vent_area"
                  ref={vent_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: ventRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.ilmanvaihto2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setVentRenovated(e.target.value);
                  }}
                  required
                  id="vent_renovated"
                  title={ventRenovatedError ? errorText2 : ""}
                  ref={vent_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[8]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: ventError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckVent(e.target.value);
                  }}
                  id="vent_plannedRenovation"
                  title={ventError ? errorText : ""}
                  ref={vent_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[18]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="vent_rPrice"
                    ref={vent_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Julkisivut</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.julkisivut1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  id="front_area"
                  ref={front_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: frontRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.julkisivut2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setFrontRenovated(e.target.value);
                  }}
                  required
                  id="front_renovated"
                  title={frontRenovatedError ? errorText2 : ""}
                  ref={front_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[3]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: frontError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckFront(e.target.value);
                  }}
                  id="front_plannedRenovation"
                  title={frontError ? errorText : ""}
                  ref={front_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[13]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="front_rPrice"
                    ref={front_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Ikkunat ja parvekkeet</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    /* preFilledData.julkisivut1 */ preResidentialArea +
                    preCommercialArea
                  }
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  required
                  disabled
                  hidden
                  id="windows_and_balconies_area"
                  ref={windows_and_balconies_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: windows_and_balconiesRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.julkisivut2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setWindows_and_balconiesRenovated(e.target.value);
                  }}
                  required
                  id="windows_and_balconies_renovated"
                  title={windows_and_balconiesRenovatedError ? errorText2 : ""}
                  ref={windows_and_balconies_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[27]}v</span> {/* Tähän window_and_balconies default value */}
                </div>
              </td>
              <td style={{ backgroundColor: windows_and_balconiesError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckWindows_and_balconies(e.target.value);
                  }}
                  id="windows_and_balconies_plannedRenovation"
                  title={windows_and_balconiesError ? errorText : ""}
                  ref={windows_and_balconies_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[26]} /* Tähän default value hinnalle */
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="windows_and_balconies_rPrice"
                    ref={windows_and_balconies_rPriceInputRef}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "left",
                  }}
                >
                  <>Salaojat ja sokkeli</>
                </div>
              </th>
              <td>
                <input
                  // defaultValue={/* preFilledData.salaojat1 */ preResidentialArea + preCommercialArea}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  disabled
                  id="yard_area"
                  ref={yard_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: yardRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.salaojat2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setYardRenovated(e.target.value);
                  }}
                  required
                  id="yard_renovated"
                  title={yardRenovatedError ? errorText2 : ""}
                  ref={yard_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[9]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: yardError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckYard(e.target.value);
                  }}
                  id="yard_plannedRenovation"
                  title={yardError ? errorText : ""}
                  ref={yard_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[19]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="yard_price"
                    ref={yard_priceInputRef}
                  />
                  <span>projekti</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    fontWeight: showYardCase ? "bold" : "normal",
                  }}
                >
                  <>Pihakansi</>
                  <div style={{ width: "5px" }}></div>
                  <input
                    style={{ width: "15px" }}
                    type="checkbox"
                    onChange={() => setShowYardCase(!showYardCase)}
                  ></input>
                </div>
              </th>
              <td>
                <input
                  defaultValue={
                    showYardCase ? preResidentialArea + preCommercialArea : 0
                  }
                  key={
                    showYardCase ? preResidentialArea + preCommercialArea : 0
                  }
                  style={inputfieldwith && { display: "none" }}
                  type="text"
                  disabled
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  id="yard_cover_area"
                  ref={yard_cover_areaInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: yardCoverRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.salaojat2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  disabled={!showYardCase}
                  onChange={(e) => {
                    setYardCoverRenovated(e.target.value);
                  }}
                  required
                  id="yard_cover_renovated"
                  title={yardCoverRenovatedError ? errorText2 : ""}
                  ref={yard_cover_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span style={showYardCase ? {} : { color: "#545454" }}>
                    {props.defaults[24]}v
                  </span>
                </div>
              </td>
              <td style={{ backgroundColor: yardCoverError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  disabled={!showYardCase}
                  onChange={(e) => {
                    setCheckYardCover(e.target.value);
                  }}
                  id="yard_cover_plannedRenovation"
                  title={yardCoverError ? errorText : ""}
                  ref={yard_cover_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[23]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    disabled={!showYardCase}
                    required
                    id="yard_cover_price"
                    ref={yard_cover_priceInputRef}
                  />
                  <span>projekti</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Katto</>
                </div>
              </th>
              <td
                style={{ backgroundColor: "#f1f1f1", paddingLeft: "8px" }}
                type="text"
                autoComplete="off"
                required
                id="roof_area"
                ref={roof_areaInputRef}
              >
                {isNotCorrect(
                  Math.round(
                    ((preResidentialArea + preCommercialArea) / preFloors) * 1.2
                  )
                )}
                {/* { isNaN((preResidentialArea + preCommercialArea) / preFloors * 1.2)
            ? 0 : Math.round((preResidentialArea + preCommercialArea) / preFloors * 1.2)
            } */}
              </td>
              <td
                style={{
                  backgroundColor: roofRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.katto2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setRoofRenovated(e.target.value);
                  }}
                  required
                  id="roof_renovated"
                  title={roofRenovatedError ? errorText2 : ""}
                  ref={roof_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[6]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: roofError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckRoof(e.target.value);
                  }}
                  id="roof_plannedRenovation"
                  title={roofError ? errorText : ""}
                  ref={roof_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={isNotCorrect2(
                      Math.round(
                        ((preResidentialArea + preCommercialArea) / preFloors) *
                          1.2
                      ) * props.defaults[16]
                    )}
                    key={isNotCorrect2(
                      Math.round(
                        ((preResidentialArea + preCommercialArea) / preFloors) *
                          1.2
                      ) * props.defaults[16]
                    )}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="roof_price"
                    ref={roof_priceInputRef}
                  />
                  <span>projekti</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <>Hissi</>
                </div>
              </th>
              <td>
                <input
                  defaultValue={preFilledData.hissi1}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  required
                  id="elevators"
                  ref={elevatorsInputRef}
                />
              </td>
              <td
                style={{
                  backgroundColor: elevatorRenovatedError ? errorColor : "",
                }}
              >
                <input
                  defaultValue={/* preFilledData.hissi2 */ preConstructed}
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setElevatorRenovated(e.target.value);
                  }}
                  required
                  id="elevators_renovated"
                  title={elevatorRenovatedError ? errorText2 : ""}
                  ref={elevators_renovatedInputRef}
                />
              </td>
              <td>
                <div>
                  <span>{props.defaults[2]}v</span>
                </div>
              </td>
              <td style={{ backgroundColor: elevatorError ? errorColor : "" }}>
                <input
                  placeholder="tuo PTS tai anna olettaa"
                  style={inputfieldwith}
                  type="text"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckElevator(e.target.value);
                  }}
                  id="elevators_plannedRenovation"
                  title={elevatorError ? errorText : ""}
                  ref={elevators_plannedRenovationInputRef}
                />
              </td>
              <td colSpan={2}>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    defaultValue={props.defaults[12]}
                    style={inputfieldwith}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    required
                    id="elevators_price"
                    ref={elevators_priceInputRef}
                  />
                  <span>kpl</span>
                </div>
              </td>
            </tr>
          </tbody>
          {/* Hissi */}
          <div className={classes.actions}>
            <button id="nappi" className={classes.actionsbutton}>
              Tallenna ja tarkastele korjausvelkaa
            </button>
          </div>
        </table>
      </form>
    </div>
  );
}

export default AddEstate;

//Idea of this page is to inform customer where we use his data
import Card from "../components/card/Card";
import { useState } from "react";
import { url } from "./HomePage";

export default function ProtectionPolicy() {
    const [isLoading, setIsLoading] = useState(true);
    fetch(
        url + "me",
        {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (window.location.pathname === "/path/tietosuoja") {
                return setIsLoading(false);
            }
            if (response.status === 401) {
                console.log("token invalid")
                window.location.href = "/";
            }
            return setIsLoading(false);
        }
        )
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <Card>
            <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                <h3>Rekisteri- ja tietosuojaseloste</h3>
                <p>
                    <b>
                        Tämä on Suomen Asuntosatama LKV Oy:n EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste.
                    </b>
                </p>
                <p>Laadittu 14.7.2022. Viimeisin muutos 14.7.2022</p>
                <div>
                    <h5>Rekisterin pitäjän tiedot</h5>
                    <p>Suomen Asuntosatama LKV Oy</p>
                    <p>Mannerheimintie 94 A</p>
                    <p>00250 Helsinki</p>
                </div>
                <div>
                    <h5>Johdanto</h5>
                    <p>
                        Sivustomme vierailijoiden yksityisyys on Korjausvelkalaskurin kehittäjille hyvin tärkeää, ja olemme sitoutuneet suojelemaan sitä.
                        Tällä sivulla saat tietää, mihin käytämme henkilötietojasi. Kontaktoi rekisterin pitäjän tietojen yhteydessä mainittuun yhteyshenkilöön,
                        mikäli on jotain kysyttävää.
                    </p>
                    <p>
                        Kun hyväksyt tietosuojakäytännön mukaisen evästeiden käyttämisen ensimmäistä kertaa sivustolla vierailessasi, hyväksyt evästeiden käytön
                        myös jokaisella tulevalla vierailukerralla.
                    </p>
                </div>
                <div>
                    <h5>Huomionosoitus</h5>
                    <p>
                        Tämä dokumentti on ottanut mallia pääsääntöisesti kahdesta mallista, jotka
                        ovat <a href="https://www.websiteplanet.com/fi/blog/muuta-tietosuojakaytantosi-gdpr-vaatimusten-mukaiseksi/">
                            websiteplanet.fi:n</a> sekä <a href="https://www.innowise.fi/fi/gdprn-mukainen-rekisteri-ja-tietosuojaselosteen-malli/">
                            innowise.fi:n
                        </a>.
                    </p>
                </div>
                <div>
                    <h5>Rekisterin nimi</h5>
                    <p>Yrityksen verkkopalvelun käyttäjärekisteri ja asiakasrekisteri</p>
                </div>
                <div>
                    <h5>Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h5>
                    <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen ksäittelylle on</p>
                    <ul>
                        <li>henkilön suostumus (dokumentoitu, vapaaehtoinen, yksilöity, tietoinen ja yksiselitteinen)</li>
                        <li>sopimus, jossa rekisteröity on osapuolena</li>
                        <li>rekisterinpitäjän oikeutettu etu (esim. asiakassuhde, työsuhde, jäsenyys).</li>
                    </ul>
                    <p>Henkilötietojen käsittelyn tarkoitus on yhteydenpito asiakkaisiin, sisäänkirjautuminen, asiakassuhteen ylläpitoo, markkinointi ja asiakaspalvelu.</p>
                    <p>Tietoja ei käytetä automatisoituun päätöksentekoon tai profiloitiin</p>
                </div>
                <div>
                    <h5>Rekisterin tietosisältö</h5>
                    <p>
                        Rekisteriin tallennettavia tietoja ovat:
                    </p>
                    <ul>
                        <li>
                            Tietoja, joita annetaan luodessasi käyttäjän: henkilön nimi, yhteystiedot (sähköpostiosoite)
                        </li>
                        <li>
                            Tietoja, jota annat käyttäessä sivua, voidaan mahdollisesti tallentaa.
                        </li>
                    </ul>
                </div>
                <div>
                    <h5>Säännönmukaiset tietolähteet</h5>
                    <p>
                        Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse,
                        sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.

                        Yritysten ja muiden organisaatioiden yhteyshenkilöiden tietoja voidaan kerätä myös julkisista lähteistä kuten verkkosivuilta,
                        hakemistopalveluista ja muilta yrityksiltä.
                    </p>
                </div>
                <div>
                    <h5>Tietojen Säännönmukaiset luovutukset</h5>
                    <p>
                        Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.
                    </p>
                    <p>
                        Luovutamme henkilötietoja viranomaisille vain pakottavan lainsäädännön edellyttämällä tavalla. Henkilötietoja voidaan luovuttaa
                        toimivaltaisen viranomaisen esittämien vaatimusten perusteella ja vain lakiin perustuvien edellytysten mukaisesti.
                    </p>
                </div>
                <div>
                    <h5>Tietojen siirto EU:n tai ETA:n ulkopuolelle</h5>
                    <p>
                        Toteutamme palvelut ja käsittelemme henkilötietoja ensisijaisesti EU- tai ETA-maissa sijaitsevia toimijoita ja palveluja hyödyntäen.
                        Joissain tapauksissa palvelujemme toteuttamiseen saatetaan käyttää myös muualla sijaitsevia toimijoita, palveluja ja palvelimia.
                    </p>
                    <p>
                        Keräämämme perustiedot käyttäjistä (esim. käyttäjän etu- ja sukunimi, sähköpostiosoite) lähetetään säännöllisesti Amerikan Yhdysvaltoihin, sillä sivuston
                        palvelin sijaitsee siellä. Sivuston palvelin on ainoa kohde Amerikan Yhdysvalloissa, johonka tietoja siirretään, eikä lähtökohtaisesti
                        siellä sijaitsevat oikeudelliset henkilöt käsittele käyttäjien tietoja.
                    </p>
                    <p>
                        Mahdollisesti myös muissakin tapauksissa henkilötietoja saatetaan siirtää
                        EU- tai ETA-maiden ulkopuolelle  sellaisiin maihin, joiden henkilötietojen käsittelyä koskeva lainsäädäntö poikkeaa Suomen lain
                        vaatimuksista.
                    </p>
                    <p>
                        Huolehdimme, että  henkilötietojen käsittely tapahtuu tämän tietosuojalausekkeen mukaisesti riippumatta siitä, missä tietoja käsitellään.
                    </p>
                    <p>
                        Luomalla käyttäjän ja/tai hyväksymällä evästeet hyväksyt nimenomaisesti säännöllisiä tiedonsiirtoja Amerikan Yhdysvaltoihin.
                    </p>
                </div>
                <div>
                    <h5>Rekisterin suojauksen periaatteet</h5>
                    <p>
                        Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti.
                        Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan
                        asiaankuuluvasti. Rekisterinpitäjä huolehtiiäyttöehdot
                    </p>
                </div>
                <div>
                    <h5>Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h5>
                    <p>
                        Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon
                        korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua,
                        pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle tai sähköpostitse. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
                        henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
                    </p>
                </div>
                <div>
                    <h5>Henkilötietojen turvallisuus</h5>
                    <p>
                        Hyväksymällä tämän tiedostat, että tiedon siirto internetin välityksellä on luonnostaan turvatonta, emmekä voi taata internetin välityksellä
                        lähetetyn tiedon turvallisuutta.
                    </p>
                    <p>
                        Olet vastuulla sivustolla käyttämäsi salasanan salassapidosta; emme koskaan pyydä sinua kertomaan salasanaasi (sivustolle kirjautumista
                        lukuun ottamatta).
                    </p>
                </div>
                <div>
                    <h5>Muutokset</h5>
                    <p>
                        Päivitämme tietosuojakäytäntöä aika ajoin julkaisemalla sivustollamme uuden version. Sinun tulisi silloin tällöin tarkistaa tämä sivu,
                        jotta varmasti ymmärrät tietosuojakäytäntöön tehdyt muutokset. Ilmoitamme muutoksista sähköpostitse tai sivustomme yksityisviestijärjestelmän
                        välityksellä.
                    </p>
                </div>
                <div>
                    <h5>Muut henkilötietojen käsittelyyn liittyvät oikeudet</h5>
                    <p>
                        Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi").
                        Niin ikään rekisteröidyillä on <a href="http://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016R0679#d1e2144-1-1">
                            muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet</a> kuten henkilötietojen käsittelyn rajoittaminen tietyissä
                        tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
                        henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
                    </p>
                </div>
            </div>
        </Card>
    )
}
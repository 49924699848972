import Card from "../card/Card";
import { url } from "../../pages/HomePage";
import { useEffect, useState } from "react";
import returnArrow from "../../assets/returnArrow.png";
import UserList from "./UserList";
import { useNavigate } from "react-router-dom";

export default function AdminView() {
  const [userData, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setUsers([]);
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });

    fetch(url + "users", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      });

    fetch(url + "users/orgs", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("orgs: ", data);
        setOrgs(data);
      });
  }, []);

  function gotoNewAccountPage() {
    navigate("/admin/new");
  }

  return (
    <Card>
      <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
        <h2>
          <center>Käyttäjien hallinta</center>
        </h2>
        {userData.privilege <= 1 && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={returnArrow} alt="takaisin" style={{ height: "40px", cursor: "pointer" }} onClick={() => window.history.back()}></img>
              <button onClick={() => gotoNewAccountPage()}>Uusi käyttäjä</button>

            </div>
            <div>
              <UserList users={[...users].sort((a, b) => {
                let na = a.name.toLowerCase(),
                  nb = b.name.toLowerCase();
                if (na < nb) {
                  return -1;
                }
                if (na > nb) {
                  return 1;
                }
                return 0;
              })} setUsers={setUsers} me={userData} orgs={Object.entries(orgs)} />
            </div>
          </div>
        )}
        {userData.privilege > 1 && (
          <div>
            <p style={{ color: "red" }}>
              Sinulla ei ole lupaa käyttää tätä osaa ohjelmasta. <br />
              <a href="/yhteystiedot">Kerro meille</a> miten päädyit tänne, jos
              päädyit jonkun nettisivun linkin kautta!
            </p>
          </div>
        )}
      </div>
    </Card>
  );
}

import { useState } from "react";
import "./ChangePass.module.css";
import Card from "../card/Card";
import { useNavigate } from "react-router-dom";
import classes from "./ChangePass.module.css";
import { url } from "../../pages/HomePage";
import x_icon from "../../assets/x-icon.png"

export default function ChangePass() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [newpassword, setNew] = useState("");
  const [confirm, setConfirm] = useState("");
  const [out, setOut] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newpassword !== confirm) {
      setOut("Salasanat eivät täsmää");
      return;
    }

    await fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setOut("Väärä salasana!");
          return response;
        }
        return response.json();
      })
      .then((data) => {
        if (out === "Väärä salasana!") {
          return;
        }
        console.log("data: ", data);
        console.log("data.username: ", data.username);

        const username = data.username;
        console.log("username", username);
        fetch(url + "users", {
          method: "put",
          body: JSON.stringify({ username, password, newpassword }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }).then((response) => {
          console.log(response);
          if (response.status === 401) {
            setOut("Luvaton salasanan muutos");
          } else {
            setOut("Salasana päivitetty");
            navigate("/");
          }
        });
      });
  };

  return (
    <div className={classes.center}>
      <Card>
        <div style={{ padding: "10%" }}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
            <h1 style={{ paddingBottom: "5%" }}>Vaihda salasana</h1>
            <img src={x_icon} alt="takaisin" style={{ height: "30px", cursor: "pointer"}} onClick={() => window.history.back()}></img>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="password">Salasana:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <label htmlFor="newpassword">Uusi salasana:</label>
            <input
              type="password"
              id="newpassword"
              onChange={(e) => setNew(e.target.value)}
              value={newpassword}
              required
            />
            <label htmlFor="newpassword">Vahvista uusi salasana:</label>
            <input
              type="password"
              id="confirm"
              onChange={(e) => setConfirm(e.target.value)}
              value={confirm}
              required
            />
            <button className={classes.button} style={{ marginTop: "3.5%", marginLeft: "0" }}>
              Päivitä salasana
            </button>
          </form>
          <div>
            <p>{out}</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

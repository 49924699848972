import "./UserList.css";
import { useEffect, useRef, useState } from "react";
import { url } from "../../pages/HomePage";

export default function UserList({ users, me, orgs }) {
  const [isEditing, setEditing] = useState(false);
  const [selectedUser, selectUser] = useState(0);
  const [usersInSet, setUsersInSet] = useState([]);
  const privilege = useRef();
  const organization = useRef();

  useEffect(() => {
    setUsersInSet(users);
  }, [users]);

  function sortUsersByPrivilege() {
    const selectedPrivilege = privilege.current.value;

    const filteredUsers = users.filter(user => {
      if (selectedPrivilege === "3") {
        return true; // Näytä kaikki käyttäjät
      } else {
        return user.privilege.toString() === selectedPrivilege;
      }
    });

    const sortedUsers = [...filteredUsers].sort((a, b) => a.privilege - b.privilege);

    setUsersInSet(sortedUsers);
  }

  function sortUsersByOrganization() {
    const selectedOrganisation = organization.current.value;
  
    const filteredUsers = users.filter(user => {
      if (selectedOrganisation === "") {
        return user.organization === 0; // Show only users with non-organizations
      } else if (selectedOrganisation === "0") {
        return true; // Show all users
      } else {
        return user.organization.toString() === selectedOrganisation;
      }
    });
  
    const sortedUsers = [...filteredUsers].sort((a, b) => a.organization - b.organization);
  
    setUsersInSet(sortedUsers);
  }

  function deleteUser(index) {
    const username = usersInSet[index].username;

    if (window.confirm("Oletko varma, että haluat poistaa tilin " + username)) {
      fetch(url + "users", {
        method: "delete",
        body: JSON.stringify({ username }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      window.location.reload();
    }
  }

  function editUser(index) {
    setEditing(true);
    selectUser(index);
    console.log(index);
  }

  function deselect() {
    setEditing(false);
  }

  function saveEdit(index, currentUsername) {
    const name = document.getElementById("name").innerText;
    const newUsername = document.getElementById("newUsername").innerText;

    let privilege;

    switch (document.getElementById("group-select").value) {
      case "1":
        privilege = usersInSet[index].privilege;
        break;
      case "2":
        switch (usersInSet[index].privilege) {
          case 0:
            privilege = 1;
            break;
          case 1:
            privilege = 0;
            break;
          case 2:
          default:
            privilege = 1;
            break;
        }
        break;
      case "3":
        switch (usersInSet[index].privilege) {
          default:
          case 0:
            privilege = 2;
            break;
          case 1:
            privilege = 2;
            break;
          case 2:
            privilege = 0;
            break;
        }
        break;
      default:
        privilege = 2;
    }

    const organization =
      parseInt(document.getElementById("org-select").value) + 1;

    fetch(url + `users/edit/${newUsername}`, {
      method: "put",
      body: JSON.stringify({
        username: currentUsername,
        name,
        privilege,
        organization,
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(response);
      window.location.reload();
    });
  }

  if (orgs.length === 0) {
    console.log("ORGS EMPTY");
    return (
      <div>
        <h3>Ladataan...</h3>
      </div>
    );
  }

  function selectUsers(term) {
    var newUsers = [];
    for (var i = 0; i < users.length; i++) {
      var user = {
        password: "",
        username: users[i].username,
        privilege: users[i].privilege,
        name: users[i].name,
        organization: users[i].organization,
      };
      if (user.name.toLowerCase().includes(term.toLowerCase())) {
        newUsers.push(user);
      } else if (user.username.toLowerCase().includes(term.toLowerCase())) {
        newUsers.push(user);
      }
    }
    if (term !== "") {
      setUsersInSet(newUsers);
    } else {
      setUsersInSet(users);
    }
  }

  return (
    <div>
      <form style={{ width: "40%", marginBottom: "15px" }}>
        <div style={{ display: "flex" }}>
          <input
            type="text"
            placeholder="Etsi..."
            onChange={(event) => {
              selectUsers(event.target.value);
            }}
          />
          <div style={{ width: "20px" }} />
          <select
            style={{ backgroundColor: "transparent" }}
            type="text"
            autoComplete="off"
            id="privilege"
            ref={privilege}
            onChange={() => sortUsersByPrivilege()}
          >
            <option value="3">Kaikki tasot</option>
            <option value="2">Peruskäyttäjä</option>
            <option value="1">Admin</option>
            <option value="0">Omistaja</option>
          </select>
          <div style={{ width: "20px" }} />
          <select
            style={{ backgroundColor: "transparent" }}
            type="text"
            autoComplete="off"
            id="organization"
            ref={organization}
            onChange={() => sortUsersByOrganization()}
          >
            <option value="0">Kaikki organisaatiot</option>
            <option value="1">Asuntosatama LKV</option>
            <option value="2">Sunny Harbor</option>
            <option value="3">Testgroup</option>
            <option value="4">Kilpailija</option>
            <option value="">N/A</option>
          </select>
        </div>
      </form>
      <table id="users">
        <tbody>
          <tr>
            <th>Käyttäjänimi</th>
            <th>Etu- ja sukunimi</th>
            <th>Taso</th>
            <th>Organisaatio</th>
            <th></th>
          </tr>
          {usersInSet.map((user, index) => {
            var organization =
              user.organization === 0
                ? "N/A"
                : orgs[user.organization - 1][1].name;
            var oldUsername = user.username;
            if (isEditing && selectedUser === index) {
              return (
                <tr key={index}>
                  <td contentEditable="true" id="newUsername">
                    {user.username}
                  </td>
                  <td contentEditable="true" id="name">
                    {user.name}
                  </td>
                  <td contentEditable="true">
                    <select id="group-select">
                      <option value="" disabled select>
                        Valitse ryhmä
                      </option>
                      <option value="1">
                        {user.privilege === 0
                          ? "Omistaja"
                          : user.privilege === 1
                          ? "Admin"
                          : "Peruskäyttäjä"}
                      </option>
                      <option value="2">
                        {user.privilege === 0
                          ? "Admin"
                          : user.privilege === 1
                          ? "Omistaja"
                          : "Admin"}
                      </option>
                      <option value="3">
                        {user.privilege === 0
                          ? "Peruskäyttäjä"
                          : user.privilege === 1
                          ? "Peruskäyttäjä"
                          : "Omistaja"}
                      </option>
                    </select>
                  </td>
                  <td contentEditable="true">
                    <select id="org-select">
                      <option value="0">N/A</option>
                      {orgs.map((org, i) => {
                        console.log("name: ", i, org[1].name);
                        return <option value={i}>{org[1].name}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <center>
                      <button
                        onClick={() =>
                          saveEdit(index, oldUsername, user.username)
                        }
                      >
                        Tallenna
                      </button>
                      <button onClick={() => deselect()}>Peruuta</button>
                    </center>
                  </td>
                </tr>
              );
            } else if (
              users[index].privilege === 0 &&
              me.username !== users[index].username
            ) {
              return (
                <tr key={index}>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td>
                    {user.privilege === 0
                      ? "Omistaja"
                      : user.privilege === 1
                      ? "Admin"
                      : "Peruskäyttäjä"}
                  </td>
                  <td>{organization}</td>
                  <td>
                    <center>
                      <button disabled style={{ color: "grey" }}>
                        Muokkaa
                      </button>
                      <button disabled style={{ color: "grey" }}>
                        Poista
                      </button>
                    </center>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={index}>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td>
                    {user.privilege === 0
                      ? "Omistaja"
                      : user.privilege === 1
                      ? "Admin"
                      : "Peruskäyttäjä"}
                  </td>
                  <td>{organization}</td>
                  <td>
                    <center>
                      <button onClick={() => editUser(index)}>Muokkaa</button>
                      <button onClick={() => deleteUser(index)}>Poista</button>
                    </center>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

import React from 'react';
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={classes.footer}>
      <div className={classes.content}>
        <p style={{ margin: "0" }}>Tekijänoikeus © Korjausvelkalaskuri Oy</p>
      </div>
      <div className={classes.content} style={{ display: "flex", gap: "3rem", margin: "0" }}>
        <Link to="/tos" style={{textDecoration: "none", color: "white"}}>Käyttöehdot</Link>
        <Link to="/tietosuoja" style={{textDecoration: "none", color: "white"}}>Tietosuoja</Link>
        <Link to="/yhteystiedot" style={{textDecoration: "none", color: "white"}}>Yhteystiedot</Link>
      </div>
    </div>
  )
}

export default Footer;
//Idea of this page is to format data, put it to server, and check our rights to be on this page
import Edit from "../components/edit/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "./HomePage";
import { useEffect, useState } from "react";

function EditEstate() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [defaults, setDefaults] = useState("");

  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 401) {
        console.log("token invalid");
        window.location.href = "/";
      }
      return setIsLoading(false);
    });
    fetch(url + "api/defaults", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Oletusarvojen lataaminen epäonnistui");
        }
        return response.json();
      })
      .then((data) => {
        setDefaults(data);
        /* console.log(data); */
      });
  }, []);

  //this function formats the data and sends request
  function handleEditForm(newData) {
    var newSewer_rPrice;
    if (Number(newData.sewer_rPrice) === defaults[17]) {
      newSewer_rPrice = 0;
    } else {
      newSewer_rPrice = newData.sewer_rPrice;
    }

    var newPlumbing_rPrice;
    if (Number(newData.plumbing_rPrice) === defaults[15]) {
      newPlumbing_rPrice = 0;
    } else {
      newPlumbing_rPrice = newData.plumbing_rPrice;
    }

    var newBathroom_price;
    if (Number(newData.bathroom_price) === defaults[10]) {
      newBathroom_price = 0;
    } else {
      newBathroom_price = newData.bathroom_price;
    }

    var newHeating_rPrice;
    if (Number(newData.heating_rPrice) === defaults[14]) {
      newHeating_rPrice = 0;
    } else {
      newHeating_rPrice = newData.heating_rPrice;
    }

    var newElec_rPrice;
    if (Number(newData.elec_rPrice) === defaults[11]) {
      newElec_rPrice = 0;
    } else {
      newElec_rPrice = newData.elec_rPrice;
    }

    var newVent_rPrice;
    if (Number(newData.vent_rPrice) === defaults[18]) {
      newVent_rPrice = 0;
    } else {
      newVent_rPrice = newData.vent_rPrice;
    }

    var newFront_rPrice;
    if (Number(newData.front_rPrice) === defaults[13]) {
      newFront_rPrice = 0;
    } else {
      newFront_rPrice = newData.front_rPrice;
    }

    var newWindows_and_balconies_rPrice;
    if (Number(newData.windows_and_balconies_rPrice) === defaults[26]) {
      newWindows_and_balconies_rPrice = 0;
    } else {
      newWindows_and_balconies_rPrice = newData.windows_and_balconies_rPrice;
    }

    var newYard_price;
    if (Number(newData.yard_price) === defaults[19]) {
      newYard_price = 0;
    } else {
      newYard_price = newData.yard_price;
    }

    var newYard_cover_price;
    if (Number(newData.yard_cover_price) === defaults[23]) {
      newYard_cover_price = 0;
    } else {
      newYard_cover_price = newData.yard_cover_price;
    }

    var newRoof_price;
    if (Number(newData.roof_price) === defaults[16]) {
      newRoof_price = 0;
    } else {
      newRoof_price = newData.roof_price;
    }

    var newElevators_price;
    if (Number(newData.elevators_price) === defaults[12]) {
      newElevators_price = 0;
    } else {
      newElevators_price = newData.elevators_price;
    }

    //plannedRenovation
    var newSewer_plannedRenovation;
    if (newData.sewer_plannedRenovation === "") {
      newSewer_plannedRenovation = 0;
    } else {
      newSewer_plannedRenovation = newData.sewer_plannedRenovation;
    }

    var newPlumbing_plannedRenovation;
    if (newData.plumbing_plannedRenovation === "") {
      newPlumbing_plannedRenovation = 0;
    } else {
      newPlumbing_plannedRenovation = newData.plumbing_plannedRenovation;
    }

    var newBathroom_plannedRenovation;
    if (newData.bathroom_plannedRenovation === "") {
      newBathroom_plannedRenovation = 0;
    } else {
      newBathroom_plannedRenovation = newData.bathroom_plannedRenovation;
    }

    var newHeating_plannedRenovation;
    if (newData.heating_plannedRenovation === "") {
      newHeating_plannedRenovation = 0;
    } else {
      newHeating_plannedRenovation = newData.heating_plannedRenovation;
    }

    var newElec_plannedRenovation;
    if (newData.elec_plannedRenovation === "") {
      newElec_plannedRenovation = 0;
    } else {
      newElec_plannedRenovation = newData.elec_plannedRenovation;
    }

    var newVent_plannedRenovation;
    if (newData.vent_plannedRenovation === "") {
      newVent_plannedRenovation = 0;
    } else {
      newVent_plannedRenovation = newData.vent_plannedRenovation;
    }

    var newFront_plannedRenovation;
    if (newData.front_plannedRenovation === "") {
      newFront_plannedRenovation = 0;
    } else {
      newFront_plannedRenovation = newData.front_plannedRenovation;
    }

    var newWindows_and_balconies_plannedRenovation;
    if (newData.windows_and_balconies_plannedRenovation === "") {
      newWindows_and_balconies_plannedRenovation = 0;
    } else {
      newWindows_and_balconies_plannedRenovation = newData.windows_and_balconies_plannedRenovation;
    }

    var newYard_plannedRenovation;
    if (newData.yard_plannedRenovation === "") {
      newYard_plannedRenovation = 0;
    } else {
      newYard_plannedRenovation = newData.yard_plannedRenovation;
    }

    var newYard_cover_plannedRenovation;
    if (newData.yard_cover_plannedRenovation === "") {
      newYard_cover_plannedRenovation = 0;
    } else {
      newYard_cover_plannedRenovation = newData.yard_cover_plannedRenovation;
    }

    var newRoof_plannedRenovation;
    if (newData.roof_plannedRenovation === "") {
      newRoof_plannedRenovation = 0;
    } else {
      newRoof_plannedRenovation = newData.roof_plannedRenovation;
    }

    var newElevators_plannedRenovation;
    if (newData.elevators_plannedRenovation === "") {
      newElevators_plannedRenovation = 0;
    } else {
      newElevators_plannedRenovation = newData.elevators_plannedRenovation;
    }

    function modifyValues(i) {
      var value = i + "";
      var newValue = value.split(",").join(".").replaceAll(" ", "");
      return newValue;
    }

    var body = `{
      "name":"${newData.name}",
      "address":"${newData.address}",
      "postNumber":"${newData.postNumber}",
      "city":"${newData.city}",
      "propertyType":${newData.propertyType},
      "constructed":${newData.constructed},
      "floors":${newData.floors},
      "apartments":${newData.apartments},
      "residentialArea":${modifyValues(newData.residentialArea)},
      "commercialArea":${modifyValues(newData.commercialArea)},
      "apartmentArea":${modifyValues(newData.apartmentArea)},
      "loanperiod":${modifyValues(newData.loanPeriod)},
      "rate":${modifyValues(newData.rate)},
      "sewer_area":${modifyValues(newData.sewer_area)},
      "sewer_renovated":${modifyValues(newData.sewer_renovated)},
      "sewer_plannedRenovation":${modifyValues(newSewer_plannedRenovation)},
      "sewer_rPrice":${modifyValues(newSewer_rPrice)},
      "sewer_rRPrice":${modifyValues(newData.sewer_rRPrice)},
      "plumbing_area":${modifyValues(newData.plumbing_area)},
      "plumbing_renovated":${modifyValues(newData.plumbing_renovated)},
      "plumbing_plannedRenovation":${modifyValues(
        newPlumbing_plannedRenovation
      )},
      "plumbing_rPrice":${modifyValues(newPlumbing_rPrice)},
      "plumbing_rRPrice":${modifyValues(newData.plumbing_rRPrice)},
      "bathrooms":${modifyValues(newData.bathrooms)},
      "bathroom_renovated":${modifyValues(newData.bathroom_renovated)},
      "bathroom_plannedRenovation":${modifyValues(
        newBathroom_plannedRenovation
      )},
      "bathroom_price":${modifyValues(newBathroom_price)},
      "bathroom_rRPrice":${modifyValues(newData.bathroom_rRPrice)},
      "heating_area":${modifyValues(newData.heating_area)},
      "heating_renovated":${modifyValues(newData.heating_renovated)},
      "heating_plannedRenovation":${modifyValues(newHeating_plannedRenovation)},
      "heating_rPrice":${modifyValues(newHeating_rPrice)},
      "heating_rRPrice":${modifyValues(newData.heating_rRPrice)},
      "elec_area":${modifyValues(newData.elec_area)},
      "elec_renovated":${modifyValues(newData.elec_renovated)},
      "elec_plannedRenovation":${modifyValues(newElec_plannedRenovation)},
      "elec_rPrice":${modifyValues(newElec_rPrice)},
      "elec_rRPrice":${modifyValues(newData.elec_rRPrice)},
      "vent_area":${modifyValues(newData.vent_area)},
      "vent_renovated":${modifyValues(newData.vent_renovated)},
      "vent_plannedRenovation":${modifyValues(newVent_plannedRenovation)},
      "vent_rPrice":${modifyValues(newVent_rPrice)},
      "vent_rRPrice":${modifyValues(newData.vent_rRPrice)},
      "front_area":${modifyValues(newData.front_area)},
      "front_renovated":${modifyValues(newData.front_renovated)},
      "front_plannedRenovation":${modifyValues(newFront_plannedRenovation)},
      "front_rPrice":${modifyValues(newFront_rPrice)},
      "front_rRPrice":${modifyValues(newData.front_rRPrice)},
      "windows_and_balconies_area":${modifyValues(newData.windows_and_balconies_area)},
      "windows_and_balconies_renovated":${modifyValues(newData.windows_and_balconies_renovated)},
      "windows_and_balconies_plannedRenovation":${modifyValues(newWindows_and_balconies_plannedRenovation)},
      "windows_and_balconies_rPrice":${modifyValues(newWindows_and_balconies_rPrice)},
      "windows_and_balconies_rRPrice":${modifyValues(newData.windows_and_balconies_rRPrice)},
      "yard_area":${modifyValues(newData.yard_area)},
      "yard_renovated":${modifyValues(newData.yard_renovated)},
      "yard_plannedRenovation":${modifyValues(newYard_plannedRenovation)},
      "yard_price":${modifyValues(newYard_price)},
      "yard_rRPrice":${modifyValues(newData.yard_rRPrice)},
      "yard_cover_area":${modifyValues(newData.yard_cover_area)},
      "yard_cover_renovated":${modifyValues(newData.yard_cover_renovated)},
      "yard_cover_plannedRenovation":${modifyValues(
        newYard_cover_plannedRenovation
      )},
      "yard_cover_price":${modifyValues(newYard_cover_price)},
      "yard_cover_rRPrice":${modifyValues(newData.yard_cover_rRPrice)},
      "roof_area":${modifyValues(newData.roof_area)},
      "roof_renovated":${modifyValues(newData.roof_renovated)},
      "roof_plannedRenovation":${modifyValues(newRoof_plannedRenovation)},
      "roof_price":${modifyValues(newRoof_price)},
      "roof_rRPrice":${modifyValues(newData.roof_rRPrice)},
      "elevators":${modifyValues(newData.elevators)},
      "elevators_renovated":${modifyValues(newData.elevators_renovated)},
      "elevators_plannedRenovation":${modifyValues(
        newElevators_plannedRenovation
      )},
      "elevators_price":${modifyValues(newElevators_price)},
      "elevators_rRPrice":${modifyValues(newData.elevators_rRPrice)},
      "checked":${newData.checked}
   }`;

    fetch(
      /* "http://192.168.10.34:8000/api/properties/" + params.id, */ url +
        "api/properties/" +
        params.id,
      {
        credentials: "include",
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      }
    ).then(() => {
      console.log(body);
      navigate("/kiinteisto/" + params.id, { replace: true });
    });
  }
  if (isLoading) {
    return null;
  }
  return <Edit onChangeOfEstate={handleEditForm} defaults={defaults} />;
}

export default EditEstate;

import "./UserList.css";
import Card from "../card/Card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { url } from "../../pages/HomePage";
import eye from "../../assets/eye-icon.png";
import classes from "./NewAccount.module.css";

export default function NewAccount() {
  const navigate = useNavigate();
  const [userData, setUser] = useState("");
  const [orgs, setOrgs] = useState([]);
  const nameInput = useRef();
  const usernameInput = useRef();
  const privilegeInput = useRef();
  const passwordInput = useRef();
  const orgInput = useRef();
  const [out, setOut] = useState("");
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });

    fetch(url + "users/orgs", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("orgs: ", data);
        setOrgs(Object.entries(data));
      });
  }, []);

  const handleNew = async (e) => {
    e.preventDefault();

    const name = nameInput.current.value;
    const username = usernameInput.current.value;
    const privilege = parseInt(privilegeInput.current.value);
    const password = passwordInput.current.value;
    const organization = parseInt(orgInput.current.value) + 1;

    console.log(privilege, privilegeInput.current.value);

    fetch(url + "signup", {
      credentials: "include",
      method: "post",
      body: JSON.stringify({
        name,
        username,
        privilege,
        password,
        organization,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 401) {
        setOut(
          "Sinulla ei ole lupaa tehdä tätä tai samalla nimellä oleva käyttäjä on jo olemassa"
        );
        return;
      }

      navigate("/admin");
    });
  };

  const togglePassword = () => {
    if (showPass === false) {
      setShowPass(true);
    } else if (showPass === true) {
      setShowPass(false);
    }
  };

  return (
    <Card>
      <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
        <h2>
          <center>Luo käyttäjä</center>
        </h2>
        {userData.privilege <= 1 && (
          <div
            style={{ "border-radius": "5px", padding: "50px", margin: "2%" }}
          >
            <form onSubmit={handleNew}>
              <div>
                <label>Käyttäjänimi</label>
                <input required type="text" id="username" ref={usernameInput} />
              </div>
              <div>
                <label>Etu- ja sukunimi</label>
                <input required type="text" id="name" ref={nameInput} />
              </div>
              <div>
                <label>Käyttäjä tyyppi</label>
                <select
                  required
                  ref={privilegeInput}
                  style={{ "background-color": "white" }}
                >
                  <option value="2">Peruskäyttäjä</option>
                  <option value="1">Admin</option>
                  <option disabled>Omistaja</option>
                </select>
              </div>
              <div>
                <label>Organisaatio</label>
                <select
                  required
                  ref={orgInput}
                  style={{ "background-color": "white" }}
                >
                  <option value="0">N/A</option>
                  {orgs.map((org, i) => {
                    return <option value={i}>{org[1].name}</option>;
                  })}
                </select>
              </div>
              <div>
                <label>Salasana</label>
                <div className={classes.inputWithButton}>
                  <input required type={showPass ? "text" : "password"} ref={passwordInput} />
                  <button
                    className={classes.showPassButton}
                    onMouseDown={() => togglePassword()}
                    style={{ margin: "0" }}
                    type="button"
                  >
                    <img
                      src={eye}
                      alt="prop"
                      style={{
                        height: "25px",
                        paddingLeft: "0.5%",
                        opacity: "0.8",
                      }}
                    />
                  </button>
                </div>
              </div>

              <input type="submit" value="Luo tili"></input>
            </form>

            <div>
              <p style={{ color: "red" }}>{out}</p>
            </div>
          </div>
        )}
        {userData.privilege >= 2 && (
          <div>
            <h5 style={{ color: "red" }}>
              Sinulla ei ole lupaa käyttää tätä ominaisuutta. MIkäli pääsit
              tänne jotenkin sivuston kautta vahingossa{" "}
              <a href="/yhteystiedot">ota yhteyttä</a>.
            </h5>
            <h5>
              <a href="/kotisivu">Palaa kotisivulle</a>
            </h5>
          </div>
        )}
      </div>
    </Card>
  );
}

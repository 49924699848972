import React, { useState, useEffect } from "react";
import { domain } from "../../App";

const inputfieldStyle = {
  border: "1px solid rgb(204, 204, 204)",
  borderRadius: "5px",
  padding: "5px",
  outline: "none",
  maxHeight: "200px",
};

const Coupons = () => {
  const [formData, setFormData] = useState({
    code: "",
    amount: 0,
    estates: false,
    extraproducts: false,
    items: {},
    start: 0,
    end: 0,
  });

  const [editCoupon, setEditCoupon] = useState(undefined);
  const [selectedCouponData, setSelectedCouponData] = useState();
  const [couponData, setCouponData] = useState([]);
  const [selectedCouponIndex, setSelectedCouponIndex] = useState(null);

  useEffect(() => {
    if (selectedCouponData) {
      setFormData(selectedCouponData);
    }
  }, [selectedCouponData]);

  // Other code...

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle date changes separately
    if (type === "datetime-local") {
      const date = new Date(value);
      // Adjust for the local time zone
      const offset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - offset);
      // Convert the date to Unix timestamp format
      const unixTimestamp = date.getTime();

      setFormData((prevState) => ({
        ...prevState,
        [name]: unixTimestamp,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]:
          type === "checkbox"
            ? checked
            : type === "number"
            ? Number(value)
            : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.amount = Number(formData.amount);
      const response = await fetch(domain + "/api/store/coupons/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        console.log("Coupon added successfully!");
        fetchCouponData();
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while adding coupon:", error);
    }
  };

  const handleEditCoupon = async (e) => {
    e.preventDefault();
    try {
      formData.amount = Number(formData.amount);
      const response = await fetch(domain + "/api/store/coupons/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        console.log("Coupon edited successfully!");
        fetchCouponData();
        setEditCoupon(undefined);
        setSelectedCouponIndex(null);
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while editing coupon:", error);
    }
  };

  const fetchCouponData = async () => {
    try {
      const response = await fetch(domain + "/api/store/coupons", {
        credentials: "include",
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data != null) {
          setCouponData(data);
        } else {
          setCouponData([])
        }
      } else {
        console.error("Failed to fetch coupon data");
      }
    } catch (error) {
      console.error("Error occurred while fetching coupon data:", error);
    }
  };

  const handleDeleteCoupon = async (uuid) => {
    try {
      const response = await fetch(domain + "/api/store/coupons/remove", {
        method: "DELETE",
        body: JSON.stringify({
          uuid: uuid,
        }),
        credentials: "include",
      });

      if (response.status === 200) {
        console.log("Coupon deleted successfully!");
        fetchCouponData();
      } else if (response.status === 400) {
        console.error("Bad request: Invalid data format");
      } else if (response.status === 500) {
        console.error("Server error: Internal server error occurred");
      } else {
        console.error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred while deleting coupon:", error);
    }
  };

  useEffect(() => {
    fetchCouponData();
  }, []);

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "80vh",
        padding: "5%",
      }}
    >
      <h1 style={{ fontSize: "28px" }}>Kuponkien hallinta</h1>
      <div style={{ display: "flex", height: "100%" }}>
        {editCoupon ? (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleEditCoupon(e)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Koodi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="code"
                  defaultValue={selectedCouponData.code}
                  key={selectedCouponData.code}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label style={{ fontWeight: "bold" }}>
                  Allennuksen määrä %
                </label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="amount"
                  defaultValue={selectedCouponData.amount}
                  key={selectedCouponData.amount}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kiinteistöille</label>
                <input
                  style={inputfieldStyle}
                  type="checkbox"
                  name="estates"
                  defaultChecked={selectedCouponData.estates}
                  key={selectedCouponData.estates}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Alkaa</label>
                <input
                  style={inputfieldStyle}
                  type="datetime-local"
                  name="start"
                  value={new Date(formData.start).toISOString().slice(0, 16)}
                  key={selectedCouponData.start}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Loppuu</label>
                <input
                  style={inputfieldStyle}
                  type="datetime-local"
                  name="end"
                  value={new Date(formData.end).toISOString().slice(0, 16)}
                  key={selectedCouponData.end}
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Oheistuotteille</label>
                <input
                  style={inputfieldStyle}
                  type="checkbox"
                  name="extraproducts"
                  defaultChecked={selectedCouponData.extraproducts}
                  key={selectedCouponData.extraproducts}
                  onChange={handleChange}
                />
              </div>
              <button type="submit">Muokkaa</button>
            </form>
          </div>
        ) : (
          <div
            style={{
              width: "50%",
              height: "100%",
              background: "white",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            }}
          >
            <form
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                margin: "2rem",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Koodi</label>
                <input
                  style={inputfieldStyle}
                  type="text"
                  name="code"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>
                  Allennuksen määrä %
                </label>
                <input
                  style={inputfieldStyle}
                  type="number"
                  name="amount"
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Kiinteistöille</label>
                <input
                  style={inputfieldStyle}
                  type="checkbox"
                  name="estates"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Alkaa</label>
                <input
                  style={inputfieldStyle}
                  type="datetime-local"
                  name="start"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>Loppuu</label>
                <input
                  style={inputfieldStyle}
                  type="datetime-local"
                  name="end"
                  onChange={handleChange}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label style={{ fontWeight: "bold" }}>Oheistuotteille</label>
                <input
                  style={inputfieldStyle}
                  type="checkbox"
                  name="extraproducts"
                  onChange={handleChange}
                />
              </div>
              <button type="submit">Luo</button>
            </form>
          </div>
        )}
        <div
          style={{
            width: "50%",
            height: "100%",
            overflow: "scroll",
            listStyle: "none",
            boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {couponData.map((coupon, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                background: "rgb(241, 241, 241)",
                borderBottom: "2px solid rgb(204, 204, 204)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h2
                  style={{
                    fontSize: "20px",
                    margin: "0",
                    paddingBottom: "3px",
                  }}
                >
                  {coupon.code}
                </h2>
                <h2 style={{ fontSize: "16px", margin: "0" }}>
                  {coupon.amount}%
                </h2>
                <h2 style={{ fontSize: "16px", margin: "0" }}>
                  {new Date(coupon.start).toLocaleDateString("fi-FI", {
                    timeZone: "Europe/Helsinki",
                  })}
                </h2>
                <h2 style={{ fontSize: "16px", margin: "0" }}>
                  {new Date(coupon.end).toLocaleDateString("fi-FI", {
                    timeZone: "Europe/Helsinki",
                  })}
                </h2>
                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      height: "35px",
                      fontSize: "14px",
                      borderColor:
                        selectedCouponIndex === index ? "#007ab6" : "",
                    }}
                    onClick={(e) => {
                      if (editCoupon === e.target) {
                        setEditCoupon(undefined);
                      } else {
                        setEditCoupon(e.target);
                      }
                      setSelectedCouponData(coupon);
                      setSelectedCouponIndex(
                        selectedCouponIndex === index ? null : index
                      );
                    }}
                  >
                    Muokkaa
                  </button>

                  <button
                    style={{ height: "35px", fontSize: "14px" }}
                    onClick={() => handleDeleteCoupon(coupon.uuid)}
                  >
                    Poista
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coupons;

import React, { useEffect, useState } from "react";
import classes from "./NavBar.module.css";
import { Link } from "react-router-dom";
import { url } from "../../pages/HomePage";

const options = [
  "Käyttöohjeet",
  "Tietosuoja",
  "Käyttöehdot",
  "Yhteystiedot",
  "Tunnustukset",
];

const options2 = ["Kirjaudu ulos", "Hallitse"];

const options3 = ["Oheistuotteet", "Välittäjät", "Kupongit", "Pyynnöt"];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [marketOpen, setMarketOpen] = useState(false);
  const [publicData, setPublicData] = useState({});

  const toggleOn = () => setIsOpen(true);
  const toggleOff = () => setIsOpen(false);

  const accountOn = () => setAccountOpen(true);
  const accountOff = () => setAccountOpen(false);

  const marketOn = () => setMarketOpen(true);
  const marketOff = () => setMarketOpen(false);

  const onOptionClicked = (value) => () => {
    setIsOpen(false);
    switch (value) {
      case "Tietosuoja":
        window.location = "/tietosuoja";
        break;
      case "Käyttöohjeet":
        window.location = "/ohjeet";
        break;
      case "Yhteystiedot":
        window.location = "/yhteystiedot";
        break;
      case "Käyttöehdot":
        window.location = "/tos";
        break;
      case "Tunnustukset":
        window.location = "/credits";
        break;
      default:
        break;
    }
  };

  const onAccountClicked = (value) => () => {
    setAccountOpen(false);
    switch (value) {
      case "Kirjaudu ulos":
        fetch(url + "signout", {
          credentials: "include",
          method: "post",
        });
        window.location = "/";
        break;
      case "Hallitse":
        window.location = "/account";
        break;
      default:
        break;
    }
  };

  const onMarketClicked = (value) => () => {
    setIsOpen(false);
    switch (value) {
      case "Oheistuotteet":
        window.location = "/verkkokauppa/oheistuotteet";
        break;
      case "Välittäjät":
        window.location = "/verkkokauppa/valittajat";
        break;
      case "Kupongit":
        window.location = "/verkkokauppa/kupongit";
        break;
      case "Pyynnöt":
        window.location = "/verkkokauppa/pyynnot";
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setPublicData(data));
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerorder}>
        <Link
          to="/kotisivu"
          style={{
            fontSize: "2rem",
            fontWeight: "normal",
            color: "white",
            textDecoration: "none",
          }}
          className="center"
        >
          Korjausvelkalaskuri
        </Link>
        <div className={classes.row}>
          <div style={{ fontSize: "1.3rem" }}>
            <Link to="/luokiinteisto" className={classes.link}>
              Lisää kiinteistö
            </Link>
          </div>
          <div
            style={{ fontSize: "1.3rem" }}
            onMouseEnter={toggleOn}
            onMouseLeave={toggleOff}
          >
            <div className={classes.link}>Tiedot ja käyttöohjeet</div>
            {isOpen && (
              <div className={classes.dropdown}>
                {options.map((option) => (
                  <p
                    className={classes.link}
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                  >
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div
            style={{ fontSize: "1.3rem" }}
            onMouseEnter={accountOn}
            onMouseLeave={accountOff}
          >
            <div className={classes.link}>Käyttäjä</div>
            {accountOpen && (
              <div className={classes.dropdown}>
                {options2.map((option) => (
                  <p
                    className={classes.link}
                    onClick={onAccountClicked(option)}
                    key={Math.random()}
                  >
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div style={{ fontSize: "1.3rem" }}>
            <Link to="/report-bug" className={classes.link}>
              Ilmoita ongelmasta
            </Link>
          </div>
          {publicData.privilege === 0 && (
            <div
              style={{ fontSize: "1.3rem" }}
              onMouseEnter={marketOn}
              onMouseLeave={marketOff}
            >
              <div className={classes.link}>Verkkokauppa</div>
              {marketOpen && (
                <div className={classes.dropdown}>
                  {options3.map((option) => (
                    <p
                      className={classes.link}
                      onClick={onMarketClicked(option)}
                      key={Math.random()}
                    >
                      {option}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

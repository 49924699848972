//Idea of this page is to welcom user and display all the estates as a list
import { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { Navigate } from "react-router-dom";
import List from "../components/estatelist/List";

export const url = "https://korjausvelkalaskuri.app:8000/";

function HomePage() {
  const [isloading, setIsLoading] = useState(true);
  const [loadedEstates, setLoadedEstates] = useState([]);
  const [publicData, setPublicData] = useState({});

  //this useEffect loads all properties and while it is doing that it displays "Sivu latautuu...". It also checks if you have rights to be on this page
  useEffect(() => {
    setIsLoading(true);
    fetch(url + "api/properties", {
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          console.log("token invalid");
          window.location.href = "/";
          return;
        }
        return response.json();
      })
      .then((data) => {
        const estates = [];
        for (const key in data) {
          const estate = {
            id: key,
            ...data[key],
          };

          estates.unshift(estate);
        }

        setIsLoading(false);
        setLoadedEstates(estates.filter((estate) => estate.propertyType === 0));
        /* console.log(estates); */
      });

    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setPublicData(data));
  }, []);

  //this if statement informs you which version are you running
  if (process.env.NODE_ENV === "production") {
    console.log("This is production vesion");
    if (document.cookie.indexOf("session_token") === -1) {
      return <Navigate to="/" replace />;
    }
  } else {
    console.log("This is developer version");
  }

  if (isloading) {
    return (
      <div className={classes.center}>
        <h1>Sivu latautuu...</h1>
      </div>
    );
  }

  function onUpdateEstate(updatedEstate) {
    const updatedEstates = loadedEstates.map((estate) => {
      if (estate.id === updatedEstate.id) {
        return updatedEstate;
      } else {
        return estate;
      }
    });
    setLoadedEstates(updatedEstates);
  }

  return (
    <div>
      <div className={classes.column}>
        <br />
        <div style={{ paddingBottom: "1%" }}>
          <h2
            style={{ textAlign: "center", paddingTop: "1.5% ", color: "black" }}
          >
            Tervetuloa Korjausvelkalaskuriin: {publicData.name}
          </h2>
        </div>
        <List
          estates={loadedEstates}
          publicData={publicData}
          onUpdateEstate={onUpdateEstate}
          setIsLoading={setIsLoading}
          setLoadedEstates={setLoadedEstates}
        />
      </div>
    </div>
  );
}

export default HomePage;

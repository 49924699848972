import Card from "../card/Card";
import { url } from "../../pages/HomePage";
import { useEffect, useState } from "react";
import returnArrow from "../../assets/returnArrow.png";
import DefaultsList from "./DefaultsList";

export default function ControlView() {
  const [userData, setUser] = useState("");
  const [defaults, setDefaults] = useState([]);
  const [out, setOut] = useState("");

  useEffect(() => {
    console.log(url + "me");
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });

    fetch(url + "api/defaults", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          setOut("Oletusarvojen lataaminen epäonnistui");
        }
        return response.json();
      })
      .then((data) => {
        setDefaults(data);
        /* console.log(data); */
      });
  }, []);

  function update() {
    fetch(url + "api/properties/update", {
      credentials: "include",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        setOut("Päivitetty");
      } else {
        setOut("Päivitys epäonnistui!");
      }
    });
  }

  function gotoImport() {
    window.location = "/import";
  }

  function downloadExport() {
    fetch(url + "api/properties/export", {
      method: "GET",
      credentials: "include",
    }).then((res) => {
      if (!res.ok) {
        setOut("Lataus epäonnistui! Päivitä sivu!");
      } else {
        return res.blob();
      }
    }).then((data) => {
      if (out !== "") {
        return;
      }
      else {
        let blobUrl = window.URL.createObjectURL(data);
        var a = document.createElement("a");
        a.download = "export_korjausvelkalaskuri_app.xlsx";
        a.href = blobUrl;
        // Force the download of the blob with a certain filename
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
  }

  function updateYear() {
    fetch(url + "api/defaults/21", {
      method: "put",
      body: new Date().getFullYear(),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(response);
      window.location.reload();
    });
  }

  return (
    <Card>
      <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
        <h1>
          <center>
            Korjausvelkalaskurin kiinteistöjen ja laskennan hallinta
          </center>
        </h1>
        {userData.privilege === 0 && (
          <div>
            <div>
              <h4>Kiinteistöjen hallinta</h4>
              <div style={{display: "flex", alignItems: "center"}}>
                <img src={returnArrow} alt="takaisin" style={{ width: "40px", cursor: "pointer" }} onClick={() => window.history.back()}></img>
                <button onClick={() => update()}  >Päivitä kiinteistöt</button>
                <button onClick={() => gotoImport()}>Tuo kiinteistöjä</button>
                <button onClick={() => downloadExport()}>Vie kiinteistöt</button>
              </div>
              <div>
                <p>{out}</p>
              </div>
            </div>
            <div>
              <h4>Remonttien oletusarvojen hallinta</h4>
              <DefaultsList defaults={defaults} />
            </div>
            <div>
              <h3>Laskennan oletusarvojen hallinta</h3>
              <h6>Tämän hetkinen vuosi ohjelmassa: {defaults[21]}</h6>
              <button onClick={() => updateYear()}>Päivitä ohjelman vuotta vuoteen {new Date().getFullYear()}</button>
            </div>
            <h6>Muista päivittää kiinteistöt, muuttaessa oletusarvoja</h6>
          </div>
        )}
        {userData.privilege !== 0 && (
          <div>
            <p style={{ color: "red" }}>
              Sinulla ei ole lupaa käyttää tätä osaa ohjelmasta. <br />
              <a href="/yhteystiedot">Kerro meille</a> miten päädyit tänne, jos
              päädyit jonkun nettisivun linkin kautta!
            </p>
          </div>
        )}
      </div>
    </Card>
  );
}

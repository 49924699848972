import React from 'react';
import { Route, Routes } from "react-router-dom";
import NavBar from "../navbar/NavBar";
import HomePage from "../../pages/HomePage";
import Raport from "../../pages/Raport";
import AddEstate from "../../pages/AddEstate";
import EditEstate from "../../pages/EditEstate";
import GuidePage from "../../pages/GuidePage";
/* import TestChart from "../chart/TestChart"; */
import ProtectionPolicy from "../../pages/DataProtectionPolicy";
import TermsOfService from "../../pages/TermsOfService";
import Import from "../import/Import";
import ContactPage from '../../pages/ContactPage';
import NotFound from '../../pages/NotFound';
import Credits from '../../pages/Credits';
import ChangePass from '../changepassword/ChangePass'
import Footer from '../footer/Footer';
import ViewAccount from '../../pages/ViewAccount';
import EditAccount from '../../pages/EditAccount';
import DeleteUser from '../../pages/DeleteUsers.js';
import SuccessfullyDeleted from '../../pages/SuccesfullyDeleted';
import classes from "./Router.module.css";
import AdminView from "../admin/AdminView";
import NewAccount from "../admin/NewAccount.js";
import CheckIfExist from '../../pages/CheckIfExist';
import ControlView from '../admin/ControlView';
import ReportBug from '../../pages/ReportBug';
import InfoPage from '../../pages/InfoPage';
import LoginActual from '../login/Login copy';
import Requests from '../../pages/online-store/Requests.js';

//verkkokauppa
import OtherProducts from '../../pages/online-store/OtherProducts.js';
import Realtors from '../../pages/online-store/Realtors.js';
import Coupons from "../../pages/online-store/Coupons.js";

function DefaultRoutes() {
  return (
<div className={classes.pageContainer}>
      <div className={classes.contentWrapper}>
        <NavBar />
        <Routes>
        <Route path="/kotisivu" element={<HomePage />} />
        <Route path="/kiinteisto/:id" element={<Raport />} />
        <Route path="/tietosuoja" element={<ProtectionPolicy />} />
        <Route path="/lisaakiinteisto" element={<AddEstate />} />
        <Route path="/luokiinteisto" element={<CheckIfExist />} />
        <Route path="/hallitse/:id" element={<EditEstate />} />
        <Route path="/ohjeet" element={<GuidePage />} />
        <Route path="/testi" element={<CheckIfExist />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/import" element={<Import />} />
        <Route path="/yhteystiedot" element={<ContactPage />} />
        <Route path="/credits" element={<Credits/>} />
        <Route path="/account" element={<ViewAccount/>} />
        <Route path="/account/password" element={<ChangePass/>}/> {/* does not check */}
        <Route path="/account/edit" element={<EditAccount/>} /> {/* does not check */}
        <Route path="/account/delete" element={<DeleteUser/>} /> {/* does not check */}
        <Route path="/account/delete/success" element={<SuccessfullyDeleted/>}/>
        <Route path="/admin" element={<AdminView />} /> {/* does not check */}
        <Route path="/admin/new" element={<NewAccount />} /> {/* does not check */}
        <Route path="/control" element={<ControlView/>} /> {/* does not check */}
        <Route path='/report-bug' element={<ReportBug />} /> {/*does not check yet */}
        <Route path="/info" element={<InfoPage />} />
        <Route path="/hiipihiljaa" element={<LoginActual/>} />
        <Route path="*" element={<NotFound />} /> 

        {/* verkkokauppa */}
        <Route path="/verkkokauppa/oheistuotteet" element={<OtherProducts />} /> 
        <Route path="/verkkokauppa/valittajat" element={<Realtors />} /> 
        <Route path="/verkkokauppa/kupongit" element={<Coupons />} />
        <Route path="/verkkokauppa/pyynnot" element={<Requests />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default DefaultRoutes;

//raportti

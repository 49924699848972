//Idea of this page give our contact info
import Card from "../components/card/Card";
import classes from "./ContactPage.module.css";
import { url } from "./HomePage";
import { useState, useEffect } from "react";

export default function ContactPage() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => { //pulls the data and checks your rights
        fetch(
            url + "me",
            {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.status === 401) {
                    console.log("token invalid")
                    window.location.href = "/";
                }
                return setIsLoading(false);
            }
            )
    }, [])
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <div className={classes.center}>
            <Card>
                <div style={{ margin: "5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                    <div>
                        <h2>
                            Korjausvelkalaskurin tiimin yhteystiedot
                        </h2>
                        <p>
                            <b>Jaakko Järvistö</b><br />
                            Toimitusjohtaja, perustaja, DI
                        </p>
                        <p>
                            <b>Erkki Murto-Koivisto</b><br />
                            Perustaja, LKV
                        </p>
                        <p>
                            <b>Tommi Aho</b><br />
                            Perustaja, kiinteistöspesialisti
                        </p>
                        <p>
                            <b>Victor Ocampo</b><br />
                            Ohjelmoija, sivuston ylläpitäjä<br />
                            victor.ocampo@korjausvelkalaskuri.app
                        </p>
                        <p>
                            <b>Leon Gustafsson</b><br />
                            Ohjelmoija, graafisen suunnitelun vastaava<br />
                            leon.gustafsson@korjausvelkalaskuri.app
                        </p>
                        <p>
                            <b>Sisu Eriksson</b><br />
                            Ohjelmoija<br />
                            sisu.eriksson@korjausvelkalaskuri.app
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    )
}
import React from 'react';
import classes from "./Import.module.css";
import { url } from "../../pages/HomePage";
import { useState, useEffect } from 'react';
/*action={url + "api/properties/import"} method="post" encType="multipart/form-data"*/

function Import() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(
      url + "me",
      {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.status === 401) {
          console.log("token invalid")
          window.location.href = "/";
        }
        return setIsLoading(false);
      }
      )
  }, [])
  const filePost = () => {
    console.log("waiting for import")
    const form = document.forms.namedItem("importfile");

    //form.addEventListener('submit', function(ev) {
    const oData = new FormData(form);
    const oReq = new XMLHttpRequest();
    oReq.open("POST", url + "api/properties/import", true);
    oReq.withCredentials = true;
    console.log(url + "api/properties/import")
    oReq.send(oData);

    //ev.preventDefault();
    //}, false);
  }
  if (isLoading) {
    return (
      null
    );
  }
  return (
    <div className={classes.center}>
      <form encType="multipart/form-data" name="importfile" action="#" onSubmit={() => filePost()}>
        <br></br>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="file"
            required
            name="import"
          />
          <button type="submit" >Lähetä</button>
        </div>
      </form>
    </div>
  )
}

export default Import
//Idea of this page is to inform user how to use our product and it also checks if you have rights to be on this page
import Card from "../components/card/Card"; 
import classes from "./GuidePage.module.css";
import { useEffect, useState } from "react";
import { url } from "./HomePage";
import kuvaaja from "../assets/kuvaaja.png";
import täyttö from "../assets/täyttöohje.png";

function GuidePage() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch(
            url + "me",
            {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.status === 401) {
                    console.log("token invalid")
                    window.location.href = "/";
                }
                return setIsLoading(false);
            }
            )
    }, [])
    if (isLoading) {
        return (
            null
        );
    }
    return (
        <div className={classes.center}>
            <Card>
                <div style={{ margin: "2.5%", paddingTop: "2.5%", paddingBottom: "2.5%" }}>
                    <h1 style={{ fontSize: "30px" }}>
                        Korjausvelkalaskurin tiedot ja käyttöohjeet
                    </h1>
                    <h1 style={{ fontSize: "17px" }}>
                        Suomen AsuntoSatama Oy LKV:n SatamaPro -tuotekehitysyksikössä syntynyt Korjausvelkalaskuri Talotutka® on internetin jälkeen suurin edistysaskel välitysalalla. Kuluttajien ja jopa välittäjien tuskallisen vaikealukuisiksi kokemat isännöitsijäntodistukset muuttuvat selkokielelle.            </h1>
                    <br></br>

                    <h1 style={{ fontSize: "17px" }}>
                        Kiinteistön luominen
                    </h1>
                    <p>Voit luoda kiinteistön <b>Lisää kiinteistö</b> napista joka sijaitsee navigaatiopalkissa sivun yläosassa. Seuraavaksi sinun täytyy syöttää kiinteistölle nimi. Jos vastaava kiinteistö on jo olemassa se ilmestyy luo kiinteistö sarakkeen alapuolelle. Kiinteistön luonnin jälkeen tulee sinun syöttää kiinteistön tiedot isännöitsiätodistuksen mukaisesti. Täytettyäsi kaikki kentät voit tallentaa kiinteistön.</p>
                    <img src={täyttö} alt="ei lataudu" style={{ width: "80%" }} />
                    <br></br>

                    <h1 style={{ fontSize: "17px" }}>
                        Raportti ja kuvaaja
                    </h1>
                    <p>Korjausvelkalaskuri laskee taloyhtiön tekniikan nykyarvon ja korjausvelan. Laskenta perustuu
                        Kiinteistöliiton, Rakennustietosäätiön ja muiden asiantuntijoiden arvioihin kerrostalon tekniikan eri osien
                        eliniästä ja korjauskustannuksista. Raportti ei perustu rakennusten kuntotutkimuksiin eikä ota huomioon
                        eri korjausvaihtoehtojen kustannuseroja.</p>
                    <p>Uudessa talossa korjausvelka on lähtökohtaisesti nolla. Rakennusten jokainen elinvuosi, jolloin
                        korjauksia ei tehdä, kasvattaa korjausvelkaa. Näiden arvojen kehitys on kuvattu tuloksiin kuuluvassa
                        käyrästössä.</p>
                    <p>Raportissa kullekin taloyhtiön tekniikan osalle esimerkiksi putkille ennustetaan korjausajankohta, mikä
                        lasketaan sen osan edellisestä korjauksesta eteenpäin käyttäen kunkin tekniikan osan keskimääräistä
                        elinikää. Keskimääräiset eliniät ovat tilastollisia arvioita, joihin vaikuttaa alkuperäinen rakennustapa ja
                        materiaalit, tehtyjen korjausten toteutustapa, talon tekniikalle tehdyt huoltotoimet sekä esim.
                        ympäristöolosuhteet. Alle 5 vuoden päähän tulevaisuuteen suunnitellut korjaukset kertoo yleensä
                        taloyhtiön pitkän tähtäimen korjaussuunnitelma, eli PTS. </p>
                    <p>Tulevien korjausten hintaan vaikuttavat tulevien korjausten toteutustapa, rakentamisen suhdanteet eli
                        työn ja materiaalien tuleva hintataso, talon rakenne ja monet muut seikat. Korjausvelkalaskuriin on
                        valittu keskimääräinen korjaustapa ja eri lähteissä olevien kustannusten tämänhetkinen keskimääräinen
                        hinta. Jos tulevista korjauksista on jo tiedossa tätä keskiarvoa tarkempia kustannuksia, ne voi myös
                        täyttää laskuriin</p>
                    <img src={kuvaaja} alt="ei lataudu" style={{ width: "80%" }} />
                    <p>Korjausvelan arvo putoaa korjattavan tekniikan osalta taloyhtiön korjauksen jälkeen nollaan. Muuten
                        korjausvelka nousee vuosittain</p>
                    <br></br>

                    <h1 style={{ fontSize: "17px" }}>
                        Muokkaaminen
                    </h1>
                    <p>Kiinteistöä pääsee muokkaamaan <b>kotisivulta</b> sekä <b>raportti</b> näkymästä. Kotisivulla kiinteistön tietoja pääsee muokkaamaan kolmesa pisteestä. Raportista kiinteistön tietoja pääsee muokkaamaan sivun yläosassa olevasta muokkausnapista.</p>
                    <br></br>

                    <h1 style={{ fontSize: "17px" }}>
                        Käyttäjän muokkaaminen
                    </h1>
                    <p>Käyttäjää pääsee muokkaamaan menemällä navigaatiopalkissa <b>Käyttäjä</b> kohdan päälle ja painamalla <b>Hallitse</b> nappia.<br /><b>Hallitse</b> näkymästä pääset tekemään erilaisia toimintoja riippuen käyttäjä oikeuksistasi.</p>
                    <p><b>Peruskäyttäjä:</b><br />Muokkaa tiliä: pääset muuntamaan etu- ja sukunimesi<br />Vaihda salasana: pääset muuntamaan salasanasi vanhasta uuteen. (käyttäjän saatuasi kannattaa vaihtaa salasana)<br />Poista tili: pääset poistamaan tilisi.</p>
                    <p><b>Omistaja:</b><br />Muokkaa tiliä: pääset muuntamaan etu- ja sukunimesi<br />Vaihda salasana: pääset muuntamaan salasanasi vanhasta uuteen. (käyttäjän saatuasi kannattaa vaihtaa salasana)<br />Käyttäjien hallinta: pääset lisäämään käyttäjiä, muokkamaan mihin organisaatioon kuulut, vaihtamaan käyttäjä oikeuksia, muuttamaan etu- ja sukunimeä ja poistamaan käyttäjiä.<br />Kiinteistön hallinta: pääset muuntamaan jokaisen tekniikan elinikää ja hintaa, tuomaan kiinteistöjä ja viemään kiinteistöjä.<br />Poista tili: pääset poistamaan tilisi.</p>
                </div>
            </Card>
        </div>
    )

}
export default GuidePage;